import { PoolDetailsFragment } from "../../../../__generated__/PoolDetailsFragment"
import { GamesPerPeriodEnum, GameTypeEnum, PeriodLengthEnum, PoolSettingsTypesEnum, SeasonEnumType, SubsectionEnum } from "../../../../common/enums"
import { GameSportType } from "../../../../common/game-enums"
import { getSegment } from "../../../../common/pool-settings-setup"
import PoolSetupGenericChoices from "../components/PoolSetupGenericChoices"
import PoolSetupStepChoice from "../components/PoolSetupStepChoice"
import PoolSetupStepRecap, { financesActionName } from "../components/PoolSetupStepRecap"
import { IPoolSetupStepFormValues, IStepChoice, IStepMapping } from "../PoolSetupTypes.d"
// import {
//   PickLockCenterImg,
// } from "../styles/PoolSetupStepForm.styles";
import PoolSetupStepInvite from "./PoolSetupStepInvite"
import PoolSetupStepMaxEntries from "./PoolSetupStepMaxEntries"
import PoolSetupStepPicksCap from "./PoolSetupStepPicksCap"
import PoolSetupStepRoundBonuses from "./PoolSetupStepRoundBonuses"
import PoolSetupStepTournamentSelect from "./PoolSetupStepTournamentSelect"
import PoolSetupRules from "./PoolSetupRules"

// TODO qac: make these variable
// const bonusesDescr = {
//   // gameWeightType: `Get extra points for each pick either based on your confidence or the playoff seeds.`,
//   // roundBonusType: `You can change the point values for each round of the playoffs.`,
//   gameWeightType: `Points for correct picks are multiplied by either: the team’s rank OR your confidence.`,
//   roundBonusType: `Correct picks are worth different points depending on the round.`,
// };

const tbDesc = `Pick the team that will cover. We’ll provide the lines.`
const notUsedValue = "notused"
export const weightsAndRoundsField = "WEIGHTS_OR_ROUNDS"

const noTiebreakerOption = {
  value: "NONE",
  human: "No Tiebreaker",
  description: `Tied teams remain tied.`,
}

const ncaafBowlTiebreakerOptions2 = [
  {
    value: "TOURNAMENT_WINNER",
    human: "Championship Winner",
    description: `Predict the winner of the Championship game.`,
    unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => values.mainTiebreaker === "TOURNAMENT_WINNER",
  },
  {
    value: "TOTAL_SCORE",
    human: "Championship Total Score",
    description: `Predict the total points scored by both teams in the championship game and the closest to the actual total wins.`,
    unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => values.mainTiebreaker === "TOTAL_SCORE",
  },
  noTiebreakerOption,
]

const pickDeadlineChoices = [
  {
    value: "BEFORE_START_OF_EACH_GAME",
    human: "Before Each Game",
    description: `Picks will lock at the start of each individual game.`,
  },
  {
    value: "BEFORE_START_OF_PERIODS_FIRST_GAME",
    human: "Before First Game",
    humanFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const usesRounds =
        pool.poolSettings.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && pool.poolSettings.periodLength === PeriodLengthEnum.ROUND
      return usesRounds ? "Before Each Round" : "Before First Game"
    },
    description: `All picks lock at the start of the first game.`,
    bodyFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const usesRounds =
        pool.poolSettings.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && pool.poolSettings.periodLength === PeriodLengthEnum.ROUND
      return `All picks lock at the start of the first game${usesRounds ? " of the round" : ""}.`
    },
  },
]

export const confTourneyDesc = `Select a single conference, combine your favorite conferences, or pick all six.`

const roundBonusesChoices = [
  {
    value: "NONE",
    human: "No",
    description: "Don't use a multiplier for correct picks per round.",
  },
  {
    value: "STANDARD",
    human: "Yes, Use Round Bonuses",
    description: "Use a multiplier to award extra points for correct picks in a specific round.",
  },
] as IStepChoice[]

const championShipWeekChoices = [
  {
    value: "false",
    human: "No",
    description: "",
  },
  {
    value: "true",
    human: "Yes, Let's Play",
    description: "Limited schedule of 10 games for this period",
  },
] as IStepChoice[]

const picksCapFields = [
  {
    value: notUsedValue,
    human: "Number of games",
    description: `Players must pick a predetermined number of games, which can be less than the amount available to pick. For example, submit your 10 most confident picks out of 20 games.
`,
  },
] as IStepChoice[]
const maxEntriesFields = [
  {
    value: notUsedValue,
    human: "Number of Entries Per Player",
    description: `Player's in the Pool can submit a single or multiple Entries into the pool.  This allows Player's to vary picking strategies as well as have multiple chances to perform well.`,
  },
] as IStepChoice[]
// PoolSetupStepMaxEntries
const roundBonusesFields = [
  {
    value: notUsedValue,
    human: "Multiplier by Round",

    description: `Example: In the Quarterfinal round, each correct pick (including seed bonus, if selected) will be multiplied by 2, Semifinals x3, and Championship x4.`,
  },
] as IStepChoice[]

const gamesPerPeriodFields = [
  {
    value: GamesPerPeriodEnum.CBS,
    human: "CBS Top Games",
    description: `CBS will choose the best matchups each week.`,
  },
  {
    value: "CUSTOM",
    human: "Custom",
    description: `You will select the games each week for your pool.`,
  },
] as IStepChoice[]
const gamesPerPeriodAllFields = [
  {
    value: GamesPerPeriodEnum.ALL,
    human: "All Games",
    description: `All games are available for picking.`,
  },
  {
    value: "CUSTOM",
    human: "Custom",
    description: `You will select the games for your pool.`,
  },
] as IStepChoice[]

const weightBonusFieldsWithSeed = [
  {
    value: "NONE",
    human: "None",
    description: `All picks are scored equally.`,
  },
  // {
  //   value: "CONFIDENCE",
  //   human: "Confidence",
  //   description: "Rank the games and earn points based on how confident you are in each pick.",
  //   unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
  //     const segment = getSegment(pool)
  //     const isBracket = segment.gameType === GameTypeEnum.BRACKET
  //     return isBracket
  //   },
  // },
  {
    value: "MULTIPLY_SEED",
    human: "Seed-Based",
    description: notUsedValue, // bodyFunc
    unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const isPostSeason = pool.season.season === SeasonEnumType.post
      return !isPostSeason
    },
    bodyFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const segment = getSegment(pool)
      const isBracket = segment.gameType === GameTypeEnum.BRACKET
      const isPlayoffWithSeed = isBracket || (pool.season.season === SeasonEnumType.post && segment.sportType === GameSportType.values.NFL.value)
      const seedString = isPlayoffWithSeed ? (isBracket ? "seed" : "playoff seed") : "rank"
      return `Earn points based on a team’s ${seedString}. Underdogs earn more points and favorites less.`
    },
  },
] as IStepChoice[]

const spreadChoiceOptions = [
  {
    value: "STRAIGHT_UP",
    human: `Straight Up`,
    description: `Just pick the team you think will win.`,
  },
  {
    value: "CBS_SPREAD",
    human: `Against the Spread`,
    description: tbDesc,
  },
] as IStepChoice[]
// const conferenceOptions = [
//   // {
//   //   value: "STANDARD",
//   //   human: "All Six",
//   //   description: "Pick the ACC, Big East, Big Ten, Big 12, Pac-12, and SEC.",
//   // },
//   // {
//   //   value: "MULTIPLE_TOURNAMENTS",
//   //   human: "Custom",
//   //   description: `Select your conferences in the next step.`,
//   // },
//   {
//     value: "MULTIPLE_TOURNAMENTS",
//     human: "All Six",
//     description: "Pick the ACC, Big East, Big Ten, Big 12, Pac-12, and SEC.",
//   },
//   {
//     value: "SINGLE_TOURNAMENT",
//     human: "One",
//     description: `Select your conference in the next step.`,
//   },
// ];

const totalScoreOption = (isNFL = false) => {
  return {
    value: "TOTAL_SCORE",
    human: notUsedValue,
    humanFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const isPostSeason = pool.season.season === SeasonEnumType.post
      if (isPostSeason) {
        if (isNFL) {
          return `NFL Championship Total Score`
        }
        return `NCAAF Championship Total Score`
      } else {
        return `Yes`
      }
    },
    description: notUsedValue,
    bodyFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const isPostSeason = pool.season.season === SeasonEnumType.post
      if (isPostSeason) {
        return `Predict the total points scored by both teams in the championship game and the closest to the actual total wins.`
      } else {
        return `Everybody guesses the total points in a specific game. Closest guess wins.`
      }
    },
    // unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
    //   return values.hasOwnProperty("secondaryTiebreaker") && values.mainTiebreaker === "TOTAL_SCORE"
    //   // const segment = getSegment(pool);
    //   // const isBracket = segment.gameType === GameTypeEnum.BRACKET;
    //   // const isPlayoffWithSeed = isBracket || (pool.season.season === SeasonEnumType.post && segment.sportType === GameSportType.values.NFL.value);
    //   // return true
    // },
  } as IStepChoice
}
const weeklyTiebreakerOptions = [totalScoreOption(), noTiebreakerOption] as IStepChoice[]
const ncaafBowlTiebreakerOptions = [
  {
    value: "TOURNAMENT_WINNER",
    human: "National Championship Winner",
    description: `Predict the winner of the championship game.`,
  },
  totalScoreOption(false),
  noTiebreakerOption,
] as IStepChoice[]

const nflPostTiebreakerOptions = [
  totalScoreOption(true),
  {
    value: "ROUND_TOTALS",
    human: "By Round",
    description: `The player with the most points in the final round wins. If that’s a tie, we’ll keep going back a round until the tie is broken.`,
    unlessFunc: (pool: PoolDetailsFragment, values: IPoolSetupStepFormValues) => {
      const segment = getSegment(pool)
      // const isBracket = segment.gameType === GameTypeEnum.BRACKET;
      const isNcaaTourney = segment.subsection === (SubsectionEnum.NcaaTournament as any)
      // const isPostSeason = pool.season.season === SeasonEnumType.post;
      return !isNcaaTourney
    },
  },
  noTiebreakerOption,
] as IStepChoice[]

const setupChoiceOptions = [
  {
    value: notUsedValue,
    human: `Express Setup`,
    bodyFunc: (pool: PoolDetailsFragment) => {
      // const parts = [] as string[];
      // // const season = pool.season
      // const segment = getSegment(pool);
      // const poolSettings = segment.poolSettings;
      // if (poolSettings.gamesPerPeriod === "CBS" && poolSettings.periodLength === "WEEKLY") {
      //   parts.push("Top 15 games of the week");
      // }
      // if (poolSettings.spreadType !== "NONE") {
      //   parts.push("against the spreads");
      // }
      // if (segment.subsection === "ConferenceTournament" && (poolSettings.tournamentIds || []).length > 1) {
      //   parts.push("all six conferences");
      // }
      // if (parts.length < 2) {
      //   parts.push("the most popular rules");
      // }
      // return `Run a pool using ${parts.join(" and ")}.  Don’t worry, you can change rules later.`;
      return `Start with our house rules and change later if you’d like.`
    },
    nextStep: "recap",
  },
  {
    value: "notused2",
    human: `Advanced Setup`,
    bodyFunc: (pool: PoolDetailsFragment) => {
      // const parts = [] as string[];
      // // const season = pool.season
      // const segment = getSegment(pool);
      // const poolSettings = segment.poolSettings;
      // if (poolSettings.gamesPerPeriod) {
      //   parts.push("selecting the games your want");
      // }
      // if (poolSettings.spreadType) {
      //   parts.push("straight up or against the spread");
      // }
      // if (segment.gameWeightType) {
      //   parts.push("confidence or seed-based weight");
      // }
      // if (segment.roundBonusType) {
      //   parts.push("round bonuses");
      // }
      // const last = parts.pop();
      // const finalParts = [parts.join(", "), last].join(", and ");
      // return `Customize your rules: select ${finalParts}.`;
      return `Choose all your rules now. It only takes a minute or two.`
    },
    // Customize your scoring and rules now. Straight up or against the spreads, select the games you want and how many picks you need to make.
    // TODO qac: uuugh, maybe not working? oh its mobile
    // body: `Customize your scoring and rules now, including weights and bonuses.`,
    // body: `Customize your scoring and rules now, including confidence or seed-based weights and round bonuses.`,
    nextStep: null,
  },
]

const confTourneyChoices = [
  {
    value: notUsedValue,
    human: `Choose Tournament(s)`,
    description: confTourneyDesc,
  },
]

export const allStepMappings = [
  {
    path: "setup-choice",
    title: "How would you like to set up your pool?",
    subtitle: "Setup Options",
    Component: PoolSetupStepChoice,
    choices: setupChoiceOptions,
  },
  // {
  //   path: "format",
  //   title: "How many conferences will you pick?",
  //   subtitle: "Choose your format",
  //   Component: PoolSetupGenericChoices,
  //   field: "format",
  //   choices: conferenceOptions,
  // },
  {
    path: "conferences",
    title: "Which Conference(s) would you like to pick?",
    subtitle: `Choose your conferences`,
    Component: PoolSetupStepTournamentSelect,
    field: "tournamentIds",
    choices: confTourneyChoices,
  },
  {
    path: "spread",
    title: "Straight Up or Against the Spread?",
    subtitle: "Format Options",
    Component: PoolSetupGenericChoices,
    field: "spreadType",
    choices: spreadChoiceOptions,
  },
  // {
  //   path: "weights-and-bonuses",
  //   // title: "Use weights or bonuses?",
  //   // subtitle: "Weights / Bonuses",
  //   title: "Do you award bonus points?",
  //   subtitle: "Pick Bonuses",
  //   Component: PoolSetupGenericChoices,
  //   field: weightsAndRoundsField,
  //   choices: [
  //     {
  //       value: "NONE",
  //       human: "No, Next Step",
  //       isDesktopAlt: true,
  //     },
  //     {
  //       value: "GAME_WEIGHTS_ONLY",
  //       // human: "Yes, Game Weights",
  //       human: "Yes, By Weight",
  //       description: bonusesDescr.gameWeightType,
  //     },
  //     {
  //       value: "ROUND_BONUSES_ONLY",
  //       // human: "Yes, Round Bonuses",
  //       human: "Yes, By Round",
  //       description: bonusesDescr.roundBonusType,
  //     },
  //     {
  //       value: "BOTH",
  //       human: "Yes, use both",
  //     },
  //   ],
  //   mobileHelperSections: [
  //     {
  //       header: `Game Weights`,
  //       body: bonusesDescr.gameWeightType,
  //     },
  //     {
  //       header: `Bonuses`,
  //       body: bonusesDescr.roundBonusType,
  //     },
  //   ],
  // },
  {
    path: "game-weights",
    title: "What game weights would you like to use?",
    subtitle: `Game Weights`,
    Component: PoolSetupGenericChoices,
    field: "gameWeightType",
    choices: weightBonusFieldsWithSeed,
  },
  // {
  //   path: "game-weights",
  //   title: "Do you want to use confidence points?",
  //   subtitle: `Game Weights`,
  //   Component: PoolSetupGenericChoices,
  //   field: "gameWeightType",
  //   choices: weightBonusFields,
  // },
  {
    path: "round-bonuses",
    title: "Do you use round bonuses?",
    subtitle: `Round Bonuses`,
    Component: PoolSetupGenericChoices,
    field: "roundBonusType",
    choices: roundBonusesChoices,
  },

  {
    path: "round-bonus-settings",
    field: "roundBonuses",
    // title: "Set Your Round Bonuses",
    title: "What Multiplier would you like to use each round?",
    subtitle: `Round Bonuses`,
    Component: PoolSetupStepRoundBonuses,
    choices: roundBonusesFields,
  },
  {
    path: "pick-deadline",
    title: "When Should Picks Lock?",
    subtitle: "Pick Deadline",
    Component: PoolSetupGenericChoices,
    field: "picksDeadlineType",
    choices: pickDeadlineChoices,
    // CenterImg: PickLockCenterImg,
  },
  {
    path: "period-tiebreaker",
    title: "Do you want to break ties in the standings?",
    subtitle: "Tie-breaker",
    Component: PoolSetupGenericChoices,
    field: "mainTiebreaker",
    choices: weeklyTiebreakerOptions,
  },
  {
    path: "main-tiebreaker",
    title: "How will you break a tie in the standings?",
    subtitle: "Tie-breaker",
    Component: PoolSetupGenericChoices,
    field: "mainTiebreaker",
    choices: nflPostTiebreakerOptions,
    // CenterImg: PickTiebreakerCenterImg1,
  },
  // {
  //   path: "secondary-tiebreaker",
  //   title: "How will you break a second tie?",
  //   subtitle: "Tie-breaker 2",
  //   Component: PoolSetupGenericChoices,
  //   field: "secondaryTiebreaker",
  //   choices: nflPostTiebreakerOptions2,
  // },
  {
    path: "main-tiebreaker-bowl",
    title: "How will you break a tie in the standings?",
    subtitle: "Tie-breaker",
    Component: PoolSetupGenericChoices,
    field: "mainTiebreaker",
    choices: ncaafBowlTiebreakerOptions,
  },
  {
    path: "secondary-tiebreaker-bowl",
    title: "How will you break a second tie?",
    subtitle: "Tie-breaker 2",
    Component: PoolSetupGenericChoices,
    field: "secondaryTiebreaker",
    choices: ncaafBowlTiebreakerOptions2,
  },
  {
    path: "game-groups",
    title: "Which games will your pool pick?",
    subtitle: `Slate of Games`,
    Component: PoolSetupGenericChoices,
    field: "gamesPerPeriod",
    choices: gamesPerPeriodFields,
  },
  {
    path: "game-groups-all",
    title: "Which games will your pool pick?",
    subtitle: `Game Choices`,
    Component: PoolSetupGenericChoices,
    field: "gamesPerPeriod",
    choices: gamesPerPeriodAllFields,
  },
  {
    path: "picks-cap",
    title: "How many games will players pick from available games?",
    subtitle: `Number of games`,
    Component: PoolSetupStepPicksCap,
    field: "maxPicksPerPeriodCount",
    choices: picksCapFields,
  },
  {
    path: "max-entries",
    title: "How many entries will players be allowed to create?",
    subtitle: `Number of entries`,
    Component: PoolSetupStepMaxEntries,
    field: "maxEntriesPerUser",
    choices: maxEntriesFields,
  },
  {
    path: "last-week",
    title: "Would you like to include conference championship week?",
    subtitle: `Championship Week`,
    Component: PoolSetupGenericChoices,
    field: "includeChampionshipRound",
    choices: championShipWeekChoices,
  },

  {
    path: "recap",
    title: "Congrats! Your pool is set up!",
    subtitle: "Recap of your rules",
    Component: PoolSetupStepRecap,
    field: notUsedValue,
  },
  {
    path: "invite",
    title: "Now tell your friends to join!",
    subtitle: "Invite Friends",
    Component: PoolSetupStepInvite,
    field: notUsedValue,
  },
] as IStepMapping[]

export const bracketMappings = [
  {
    path: "",
    title: "Info",
    subtitle: "Setup Options",
    Component: PoolSetupStepChoice,
  },
  {
    path: "game-weights",
    title: "Rules",
    subtitle: `How would you like to score your bracket?`,
    Component: PoolSetupRules,
    field: "gameWeightType",
  },
  {
    path: "invite",
    title: "Invite",
    subtitle: "Here’s how to invite friends...",
    Component: PoolSetupStepInvite,
  },
] as IStepMapping[]

export const stepToPath = ({ path }) => path
const postfix = ["recap", "invite"]
const confBsteps = ["setup-choice", "conferences", "game-weights", "round-bonuses", "round-bonus-settings"].concat(postfix)
// const bracketSteps = ["setup-choice", "max-entries", "game-weights", "round-bonuses", "round-bonus-settings", "main-tiebreaker"].concat(postfix)
const regularPickemSteps = ["setup-choice", "spread", "period-tiebreaker", "game-groups", "picks-cap", "last-week"].concat(postfix)
const ncaafBowlSteps = [
  "setup-choice",
  "spread",
  "game-weights",
  "game-groups-all",
  "picks-cap",
  "pick-deadline",
  "main-tiebreaker-bowl",
  "secondary-tiebreaker-bowl",
].concat(postfix)
const nflpickemSteps = ["setup-choice", "spread", "game-weights", "round-bonuses", "round-bonus-settings", "pick-deadline", "main-tiebreaker"].concat(
  postfix,
)
export const stepMappingFor = (gameInstanceUid?: string, seasonType?: string, gameType?: string, pool?: PoolDetailsFragment) => {
  if (gameInstanceUid === undefined) {
    return allStepMappings
  }
  // const isPostSeason = seasonType === SeasonEnumType.post;
  // console.log(`here: ${gameInstanceUid} ${seasonType} ${gameType}`, pool);
  if (gameType === GameTypeEnum.PICKEM) {
    if (seasonType === SeasonEnumType.post) {
      const filterRegexp = /ncaaf/.test(gameInstanceUid) ? ncaafBowlSteps : nflpickemSteps
      return allStepMappings.filter(({ path }) => filterRegexp.includes(path))
    } else {
      const result = allStepMappings.filter(({ path }) => regularPickemSteps.includes(path))
      return result
    }
  }
  if (/ncaab-confbracket/.test(gameInstanceUid)) {
    return allStepMappings.filter(({ path }) => confBsteps.includes(path))
  }
  if (/nfl-pickem/.test(gameInstanceUid)) {
    return allStepMappings.filter(({ path }) => nflpickemSteps.includes(path))
  }
  if (/nfl-parlay/.test(gameInstanceUid)) {
    return allStepMappings.filter(({ path }) => nflpickemSteps.includes(path))
  }
  if (/ncaab-tournament/.test(gameInstanceUid)) {
    // return allStepMappings.filter(({ path }) => bracketSteps.includes(path))
    return bracketMappings
  }
  if (/ncaaw-tournament/.test(gameInstanceUid)) {
    // return allStepMappings.filter(({ path }) => bracketSteps.includes(path))
    return bracketMappings
  }
  throw new Error(`unhandled gameInstanceUid: ${gameInstanceUid} (${seasonType} ${gameType})`)
}

// export const stepOrder = stepMappings.map(stepToPath);
// .replace('roundBonusType',weightsAndRoundsField)
// export const fieldToStep = (fieldName: string) => (allStepMappings.find(({field}) => field === fieldName) || {path: fieldName}).path;
export const getFormPercent = (currentStep: string | null, pool: PoolDetailsFragment | null) => {
  const stepMappings = pool ? stepMappingFor(pool.gameInstanceUid, pool.season.season, getSegment(pool).gameType, pool) : allStepMappings
  const stepOrder = stepMappings.map(stepToPath)
  if (!currentStep) {
    // Name pool: 5% (so it doesnt go backwards if NOT express)
    return 5
  }
  // NOTE qac: invite is the last one
  const totalSteps = stepOrder.length - 1
  // NOTE qac: we decided to mark "recap" as 100% in 2019...
  if (currentStep === "recap") {
    return 98.5
  }
  if (currentStep === "invite") {
    return 98.5
  }
  // hack for pool constitution junk
  if (currentStep.includes(financesActionName)) {
    return 98.5
  }
  const index = stepOrder.indexOf(currentStep) + 1
  if (index < 1) {
    throw new Error(`unknown step: ${currentStep} (known: ${stepOrder.join(",")} for ${pool && pool.gameInstanceUid})`)
  }
  return Math.round((index / totalSteps) * 100)
}
// ["setup-choice", "format", "spread", "weights-and-bonuses", "game-weights", "round-bonuses", "round-bonus-settings", "pick-deadline", "main-tiebreaker", "secondary-tiebreaker", "recap", "invite"]
export const nextStepFor = (currentStep: string, pool: PoolDetailsFragment, value?: any, currentSettings?: any) => {
  const stepMapping = stepMappingFor(pool.gameInstanceUid, pool.season.season, getSegment(pool).gameType, pool)
  const stepOrder = stepMapping.map(stepToPath)
  const currentIndex = stepOrder.indexOf(currentStep)
  // console.log(`currentStep: ${currentStep} (${value})`, currentSettings)
  // CASE: main tiebreaker decline:
  if (currentStep === "main-tiebreaker" && value === "NONE" && /secondary-tiebreaker/.test(stepOrder.join("|"))) {
    return stepOrder[currentIndex + 2]
  }
  // CASE: WEIGHTS_OR_ROUNDS:
  // value: "NONE"
  // value: "GAME_WEIGHTS_ONLY",
  // value: "ROUND_BONUSES_ONLY",
  // value: "BOTH",
  // const hasGameWeightsStep = stepOrder.indexOf("game-weights") > -1;
  // const hasRoundBonusesStep = stepOrder.indexOf("round-bonuses") > -1;
  // if (currentStep === "weights-and-bonuses") {
  //   let idx = 4;
  //   if (currentSettings && currentSettings.roundBonusType !== "NONE") {
  //     idx = 3;
  //   }
  //   if (currentSettings && currentSettings.gameWeightType !== "NONE" && hasGameWeightsStep) {
  //     idx = 1;
  //   }
  //   if (!hasGameWeightsStep) {
  //     idx = idx - 1;
  //   }
  //   if (!hasRoundBonusesStep) {
  //     idx = idx - 1;
  //   }
  //   return stepOrder[currentIndex + idx];
  // }
  // CASE: gameWeightType:
  // if (currentStep === "game-weights" && currentSettings) {
  //   if (currentSettings.roundBonusType !== "STANDARD") {
  //     return stepOrder[currentIndex + 3];
  //   }
  //   return stepOrder[currentIndex + 2];
  // }
  // // CASE: roundBonusType STANDARD:
  if (currentStep === "round-bonuses" && value !== "STANDARD") {
    return stepOrder[currentIndex + 2]
  }
  // // // CASE: maxPicksPerPeriodCount from gamesPerPeriod screen:
  // if (currentStep === "game-groups" && value !== "CUSTOM") {
  //   return stepOrder[currentIndex + 2];
  // }
  // // CASE: format ALL:
  // if (currentStep === "format" && value === "MULTIPLE_TOURNAMENTS") {
  //   return stepOrder[currentIndex + 2];
  // }
  if (stepOrder.length <= currentIndex + 1) {
    return null
  }
  return stepOrder[currentIndex + 1]
}
