import { Field, Form, FormikErrors, FormikProps, withFormik } from "formik"
import React from "react"
import { useLocation, useRouteMatch } from "react-router"
import { extractValidationError } from "../../../../common/apiErrors"
import { ENUM_MANAGER } from "../../../../common/enums"
import { withoutDomain } from "../../../../common/url-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { errorClassFor, FormErrors, FormSuccessStatus } from "../../../components/Form"
import Link from "../../../components/Link"
import SeasonDisclaimer from "../../../components/SeasonDisclaimer"
import Spinner from "../../../components/Spinner"
import usePoolData from "../../../hooks/usePoolData"
import Analytics from "../../../utils/analytics"
import { ReactivateEntries } from "../../NonPoolPages/components/OnboardingView"
import { ICreatePoolFormProps, ICreatePoolFormValues, IPoolCreateProps } from "../PoolSetupTypes.d"
import { FieldLabel, FormActionsContainer, FormButton, FormGutters, StyledForm } from "../styles/CommonPoolSetup.styles"
import StepHeader from "./StepHeader"

const CreatePoolFormComponent = (props: IPoolCreateProps & FormikProps<ICreatePoolFormValues>) => {
  const { isSubmitting, isValid, isUpdate, isInPromoVersion, seasonId } = props
  const location = useLocation()
  const match = useRouteMatch<any>()
  if (!match) {
    return null
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCurrentEntries, gameInstancesForArea, allSegments } = usePoolData({ location, match } as any)
  const segmentWithSeason = allSegments.find((seg) => seg.season.id === seasonId)
  if (!segmentWithSeason) {
    return null
  }
  const season = segmentWithSeason.season
  const gameInstance = gameInstancesForArea.find((gi) => gi.uid === season.gameInstance.uid)
  const isManagerAndOpen = season.isCurrent && !!gameInstance && gameInstance.poolType === ENUM_MANAGER
  // console.log(`'season' ${isManagerAndOpen}`);
  // console.dir(season);
  const title = (isUpdate && "Name and Password") || (isManagerAndOpen && "What would you like to name your pool?") || `Pool Creation Closed`
  const subtitle = (isUpdate && " ") || (isManagerAndOpen && "Pool Name") || "Season Over"

  const actionContainers = (
    <FormActionsContainer>
      <FormButton as={Link} to={withoutDomain(segmentWithSeason.baseUrl)}>
        Continue Playing
      </FormButton>
    </FormActionsContainer>
  )

  const formGutters = (
    <FormGutters>
      {isUpdate && <FieldLabel htmlFor="pool-name">Name</FieldLabel>}
      <Field
        className={errorClassFor(props, "name")}
        type="text"
        name="name"
        autoComplete="off"
        id="pool-name"
        placeholder={isUpdate ? "Name" : "Name Your Pool"}
      />
      {isUpdate && <FieldLabel htmlFor="pool-password">Password</FieldLabel>}
      <Field
        className={errorClassFor(props, "password")}
        type={isUpdate ? "text" : "password"}
        name="password"
        autoComplete="off"
        placeholder="Password"
        id="pool-password"
      />
      <FormErrors {...props} />
      <FormActionsContainer>
        <FormButton
          className={`is-valid--${isValid} is-submitting--${isSubmitting} is-form-spinner`}
          as="button"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          <span>{props.status === FormSuccessStatus ? (isUpdate ? "Saved!" : "Pool Created!") : isUpdate ? "Update" : "Start Your Pool"}</span>
          <Spinner />
        </FormButton>
      </FormActionsContainer>
    </FormGutters>
  )

  return (
    <StyledForm as={Form} className={`is-update--${!!isUpdate} is-in-promo-version--${!!isInPromoVersion}`}>
      <AnalyticScreen
        feature={isUpdate ? "update" : `initial setup`}
        subfeature="set name"
        title={`Office Pool - ${isUpdate ? "Update" : "Setup"} - Set name`}
      />
      <StepHeader title={title} subtitle={subtitle} />
      {!isUpdate && !isManagerAndOpen ? actionContainers : formGutters}

      {!isUpdate && <ReactivateEntries allCurrentEntries={allCurrentEntries} gameInstances={gameInstancesForArea} allSegments={allSegments} />}
      <SeasonDisclaimer season={season} />
    </StyledForm>
  )
}

const createPoolFormikOptions = {
  mapPropsToValues: (props) => {
    return {
      name: props.initialName || "",
      password: props.initialPassword || "",
      seasonId: props.seasonId,
      poolId: props.poolId,
    }
  },

  validate: (values: ICreatePoolFormValues) => {
    const errors: FormikErrors<any> = {}
    if (!values.name) {
      errors.name = "Please fill in your pool name"
    }
    if (!values.password) {
      errors.password = "Please fill in a pool password"
    }
    return errors
  },

  handleSubmit: (variables, actions) => {
    // console.log(`variables:`)
    // console.dir(variables)
    // console.log(`actions:`)
    // console.dir(actions)
    const { mutation, switchStep, isUpdate, nextStep } = actions.props
    Analytics.trackInteraction("name pool - submit")
    return mutation({ variables })
      .then((res) => {
        // console.log('res')
        // console.dir(res)
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        setTimeout(() => {
          switchStep(isUpdate ? null : nextStep || "setup-choice", res.data.upsertPool.pool.id)
        }, 900)
        Analytics.trackInteraction("name pool - success")
      })
      .catch((err) => {
        // console.log('err')
        // console.dir(err)
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
        Analytics.trackInteraction("name pool - fail")
      })
  },
}

const CreatePoolForm = withFormik<ICreatePoolFormProps, ICreatePoolFormValues>(createPoolFormikOptions)(CreatePoolFormComponent)

export default CreatePoolForm
