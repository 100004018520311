import React from "react"
import styled from "styled-components/macro"
import CarrotSvg from "../../../components/icons/Carrot"
import { fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

const NexusSelectStyled = styled.div`
  & .Dropdown {
    font-size: ${pxToRem(12)};
    font-weight: ${fontWeight.semiBold};
    position: relative;
    text-align: left;
    display: block;
  }
  & .Dropdown--desktop {
    display: inline-block;
  }
  & .Dropdown-selectList,
  .Dropdown-selectedText,
  .Dropdown-list {
    background-color: #fff;
    border: ${pxToRem(1)} solid ${palette.gray3};
    border-radius: ${pxToRem(2)};
    padding: ${pxToRem(9)} ${pxToRem(28)} ${pxToRem(8)} ${pxToRem(16)};
  }
  & .Dropdown-selectList,
  .Dropdown-selectedText {
    font-weight: ${fontWeight.semiBold};
    padding-right: 4em;
  }
  & .Dropdown-listItem.is-selected > * {
    color: ${palette.default};
    white-space: nowrap;
  }
  & .DropdownButton-selectList,
  .Dropdown-selectList {
    appearance: none;
    cursor: pointer;
    width: 100%;
  }
  & .Dropdown-selectArrow {
    position: absolute;
    height: 1em;
    width: 1em;
    right: 1em;
    top: 50%;
    margin-top: -0.5em;
    transform: rotate(90deg);
    transition: all 0.2s ease;
    pointer-events: none;
  }
  & .Dropdown-selectedText {
    color: ${palette.default};
    cursor: pointer;
    display: inline-flex;
    white-space: nowrap;
    width: 100%;
  }
  & .Dropdown-list {
    box-shadow: 0 ${pxToRem(2)} ${pxToRem(12)} -${pxToRem(4)} rgba(19, 22, 26, 0.2);
    margin: ${pxToRem(12)} 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${pxToRem(6)} 0;
    position: absolute;
    z-index: ${zIndexes.ui2};
    list-style: none;
    background-color: #fff;
    border: ${pxToRem(1)} solid ${palette.gray3};
    border-radius: ${pxToRem(2)};
    max-height: 30vh;
  }
  .Dropdown-list:before {
    border: solid ${palette.gray3};
    border-color: transparent transparent ${palette.gray3} transparent;
    border-width: 0 ${pxToRem(6)} ${pxToRem(4)} ${pxToRem(6)};
    content: "";
    position: absolute;
    left: ${pxToRem(11)};
    top: -${pxToRem(5)};
  }
  & .Dropdown-listItem > * {
    color: ${palette.gray2};
    display: block;
    font-weight: ${fontWeight.semiBold};
    padding: ${pxToRem(8)} ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(16)};
  }
  & .Dropdown.is-active .Dropdown-selectArrow {
    transform: rotate(-90deg);
  }
  & .Dropdown-listContainer {
    position: relative;
  }
  @media (hover: hover) {
    & .Dropdown-selectList:hover,
    .Dropdown-selectedText:hover {
      background-color: ${palette.blue4};
    }

    & .Dropdown-listItem > *:hover {
      background-color: ${palette.blue4};
      color: ${palette.blue2};
    }
  }
  & .Dropdown-selectList:active,
  .Dropdown-selectedText:active {
    background-color: ${palette.blue4};
  }
  @media (hover: hover) {
    & .Dropdown--mobile {
      display: none !important;
    }
  }
  @media (hover: none), (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    &.disable-native-select--false .Dropdown--desktop {
      display: none !important;
    }
    &.disable-native-select--false .Dropdown--mobile {
      display: block !important;
    }
  }
`

const DropdownLink = styled.div`
  cursor: pointer;
`

interface IOption {
  label: string | any
  value: string | number | null
  to?: string | any
  as?: any
}

type TSetFieldValue = (name: string, value: any) => void

interface IForm {
  setFieldValue: TSetFieldValue
}
export interface IField {
  name: string
  value: string | number | null
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  [attr: string]: any
}

export interface INexusField extends Omit<IField, "onChange"> {
  onChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
}

interface IProps {
  field: INexusField
  form: IForm
  options: IOption[]
  placeholder?: string
  ariaDescribedby?: string
  defaultValue?: string
  className?: string
  readOnly?: boolean
  disableNativeSelect?: boolean
  disabled?: boolean
}
interface IState {
  isActive: boolean
}

class NexusSelect extends React.PureComponent<IProps, IState> {
  public state = {
    isActive: false,
  }

  public onClick = (_evt: React.MouseEvent) => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  public selectOption = (evt: React.MouseEvent) => {
    const { field, form, options } = this.props
    const defaultValue = this.defaultValue()
    const targetEl = evt.target as any
    // console.dir(targetEl)
    evt.preventDefault()
    if (targetEl) {
      if (targetEl.hasAttribute("href")) {
        return
      }
      // console.dir(options)
      // console.dir(evt.target)
      // NOTE qac: dataset always stringifies the value (if its an integer)
      const selectedValue = targetEl.dataset.value || defaultValue
      const selected = options.find(({ value }) => (value || defaultValue).toString() === selectedValue)
      if (selected) {
        form.setFieldValue(field.name, selected.value)
      } else {
        console.warn(`no value for: `, evt.target)
      }
    }
    this.onClick(evt)
  }

  public defaultValue() {
    return this.props.defaultValue || ""
  }

  public render() {
    // console.dir(this.props);
    const { isActive } = this.state
    const {
      field,
      form,
      options,
      disableNativeSelect,
      placeholder,
      ariaDescribedby,
      defaultValue: _d,
      readOnly,
      className,
      disabled,
      ...rest
    } = this.props
    // console.log("props");
    // console.dir(this.props);
    const defaultValue = this.defaultValue()
    const selected = options.find(({ value }) => value === field.value)
    const hasDefaultValueInOptions = !!options.find(({ value }) => value === defaultValue)
    const fullClassNames = [`nexus-select`, `disable-native-select--${!!disableNativeSelect}`]
    if (className) {
      fullClassNames.push(className)
    }

    // console.dir(rest)
    return (
      <NexusSelectStyled {...rest} className={fullClassNames.join(" ")} aria-describedby={ariaDescribedby}>
        <div className={`Dropdown Dropdown--desktop Dropdown--left${isActive ? " is-active" : ""}`} onClick={this.onClick}>
          <div className="Dropdown-listContainer">
            <button disabled={!!readOnly || !!disabled} type="button" className="Dropdown-selectedText">
              <span>{(selected && selected.label) || placeholder || defaultValue || "\u00A0"}</span>
              <CarrotSvg className="Dropdown-selectArrow" />
            </button>
            {isActive && (
              <ul className="Dropdown-list">
                {options.map(({ label, value, ...restOptionProps }) => {
                  const isSelected = (field.value || defaultValue) === (value || defaultValue)
                  return (
                    <li className={`Dropdown-listItem DropdownToggle${isSelected ? " is-selected" : ""}`} key={value || defaultValue}>
                      <DropdownLink
                        {...restOptionProps}
                        className="Dropdown-link DropdownToggle-text"
                        data-value={value || defaultValue}
                        onClick={this.selectOption}
                      >
                        {label}
                      </DropdownLink>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
        {(!disableNativeSelect && (
          <div className="Dropdown Dropdown--mobile">
            <select
              disabled={!!disabled || !!readOnly}
              className="Dropdown-selectList DropdownToggle"
              placeholder={placeholder}
              {...field}
              value={field.value || defaultValue}
            >
              {placeholder && !hasDefaultValueInOptions ? <option value={defaultValue}>{placeholder}</option> : null}
              {options.map(({ label, value }) => (
                <option value={value || defaultValue} key={value || defaultValue}>
                  {label}
                </option>
              ))}
            </select>
            <CarrotSvg className="Dropdown-selectArrow" />
          </div>
        )) ||
          null}
      </NexusSelectStyled>
    )
  }
}

export default NexusSelect
