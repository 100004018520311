import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import scale from "./scale"
import slide from "./slide"
// import {
//   FlexCol, FlexColGrow, FlexCols, FlexRow,
// } from "../../components/FlexComponents";

export { slide, scale }

// the childFactory allows to change the transition of the leaving component
// https://github.com/reactjs/react-transition-group/issues/182
const childFactoryCreator = (props) => (child) => React.cloneElement(child, props)

export default ({ transition = "", duration = 0, pageKey, children, reversed = false, noTransition = false }) => {
  if (noTransition) {
    return <div>{children}</div>
  }
  const classNames = `${transition}${reversed && transition ? "-reversed" : ""}`
  return (
    <TransitionGroup childFactory={childFactoryCreator({ classNames, timeout: duration })}>
      <CSSTransition classNames={classNames} timeout={duration} key={pageKey}>
        {/* you should wrap CSSTransition child in a div in case it could be null
        see https://github.com/reactjs/react-transition-group/issues/208 */}
        <div>{children}</div>
      </CSSTransition>
    </TransitionGroup>
  )
}
