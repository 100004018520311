import * as React from "react"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { nextStepFor } from "../components/StepMappings"
import { IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepFormCols, PoolSetupStepFormRow } from "../styles/CommonPoolSetup.styles"

const PoolSetupStepChoice = (props: IPoolSetupStepProps) => {
  const { switchStep, pool, isUpdate, choices } = props
  // console.dir(props);
  return (
    <PoolSetupStepFormRow>
      <AnalyticScreen feature={`${isUpdate ? "initial " : ""}setup`} subfeature={"select"} title={`${isUpdate ? "Initial " : ""}Setup - Select`} />
      {choices &&
        choices.map((option) => (
          <PoolSetupStepFormCols key={option.value}>
            <FormButton as="button" type="button" onClick={(evt) => switchStep(option.nextStep || nextStepFor("setup-choice", pool), pool.id, evt)}>
              {option.human}
            </FormButton>
            <p>{option.bodyFunc(pool)}</p>
          </PoolSetupStepFormCols>
        ))}
    </PoolSetupStepFormRow>
  )
}

export default PoolSetupStepChoice
