import { Field } from "formik"
import React from "react"
import { ENUM_CONFERENCE_TOURNAMENT, GameTypeEnum } from "../../../../common/enums"
import { getSegment, tournamentRBLabels } from "../../../../common/pool-settings-setup"
import { sortByOrder } from "../../../../common/sorters"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { errorClassFor } from "../../../components/Form"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepFormCols, PoolSetupStepFormRow, PoolSetupStepRoundBonusesCols } from "../styles/CommonPoolSetup.styles"
import PoolSetupDesktopDescription from "./PoolSetupDesktopDescription"

const PoolSetupStepRoundBonuses = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  // console.log('PoolSetupStepRoundBonuses')
  // console.dir(props);
  const { pool, isUpdate } = props
  const segment = getSegment(pool)
  const periods = segment.periods.edges.map((e) => e.node).sort(sortByOrder)
  const isBracket = segment.gameType === GameTypeEnum.BRACKET
  const isConfTourneyBracket = isBracket && segment.subsection === ENUM_CONFERENCE_TOURNAMENT
  // console.log(`isBracket: ${isBracket} `)
  console.dir(periods)
  const values =
    (props.values.roundBonuses.length && props.values.roundBonuses) ||
    (isBracket && segment.poolSettings.__typename === "PoolSettings" && segment.poolSettings.roundBonuses) ||
    periods.map((per) => per.order)
  return (
    <React.Fragment>
      <AnalyticScreen
        feature={`${isUpdate ? "initial " : ""}setup`}
        subfeature={"set round bonuses"}
        title={`${isUpdate ? "Initial " : ""}Setup - Set Round Bonuses`}
      />
      <PoolSetupStepFormRow>
        {isBracket
          ? values.map((value, idx) => {
              const id = `roundBonuses[${idx}]`
              const label = isConfTourneyBracket ? tournamentRBLabels[idx] : periods[idx + 1]?.description
              return (
                <PoolSetupStepRoundBonusesCols key={id}>
                  <label htmlFor={id}>{label}</label>
                  <Field className={errorClassFor(props, id)} id={id} type="number" value={value} name={id} min={1} max={1000} required={true} />
                </PoolSetupStepRoundBonusesCols>
              )
            })
          : periods.map((period) => {
              // console.log('period:')
              // console.dir(period)
              const idx = period.order - 1
              const id = `roundBonuses[${idx}]`
              const value = values.length - 1 < idx ? null : values[idx]
              return (
                <PoolSetupStepRoundBonusesCols key={period.id}>
                  <label htmlFor={id}>{period.description}</label>
                  <Field className={errorClassFor(props, id)} id={id} type="number" value={value} name={id} min={1} max={1000} required={true} />
                </PoolSetupStepRoundBonusesCols>
              )
            })}
      </PoolSetupStepFormRow>
      <PoolSetupStepFormRow>
        <PoolSetupStepFormCols>
          <FormButton as="button" type="submit">
            Set Your Bonuses
          </FormButton>
        </PoolSetupStepFormCols>
      </PoolSetupStepFormRow>
      <PoolSetupDesktopDescription {...props} />
    </React.Fragment>
  )
}

export default PoolSetupStepRoundBonuses
