import { Mutation, Query } from "@apollo/client/react/components"
import React from "react"
import { Route, Switch } from "react-router"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { PoolDetailsQuery, PoolDetailsQueryVariables } from "../../../../__generated__/PoolDetailsQuery"
import { UpsertPoolMutation, UpsertPoolMutationVariables } from "../../../../__generated__/UpsertPoolMutation"
import { stepToPath } from "../../../../common/pool-settings-setup"
import { TPoolRouteProps } from "../../../../routes.d"
import NotFound from "../../../Base/NotFound"
import ErrorView from "../../../components/ErrorView"
import LoadingView from "../../../components/LoadingView"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import Transitions, { slide } from "../../../components/Transitions"
import { entryHasManagerRole, extractPoolOgProps } from "../../../hooks/usePoolData"
import { placeholderEntry } from "../../../utils/data-utils"
import { getCreatePoolParams, withoutDomain } from "../../../utils/url-utils"

import FixedBgScreen from "../../NonPoolPages/styles/FixedBgScreen.styles"
import { POOL_DETAILS_QUERY, UPSERT_POOL_MUTATION } from "../../queries"
import CreatePoolForm from "../components/CreatePoolForm"
import MobileBackBtn from "../components/MobileBackBtn"
import PoolFormStepper from "../components/PoolFormStepper"
import { toggleEditActionName } from "../components/PoolSettingsEditBtn"
import PoolSetupStepForm from "../components/PoolSetupStepForm"
import PoolSetupStepRecap from "../components/PoolSetupStepRecap"
import { getFormPercent, stepMappingFor } from "../components/StepMappings"
import { IPoolSetupProps, TSwitchStep } from "../PoolSetupTypes.d"
import { PoolFormContainer, PoolFormTransitionContainer, PoolSetupTopUi } from "../styles/CommonPoolSetup.styles"
import { poolCreateRegexp } from "../../../../common/url-utils"
import BracketPoolSetupCommon from "../components/BracketPoolSetupCommon"
import CreateBracketPoolForm from "../components/CreateBracketPoolForm"
import BracketPoolSettingsForm from "../components/BracketPoolSettings/BracketPoolSettingsForm"
import { buildDisclaimer } from "../../../../common/game-text"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { isMarchMadnessMatcher, isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"
import constants from "../../../../common/constants"
// import constants from '../../../../common/constants';

const getPathPrefix = (match: any) => {
  // isExact: true
  // params:
  //  gameType: "bracket"
  //  sportType: "college-basketball"
  //  subsection: "conference-tournament"
  // path: "/:sportType/:subsection/:gameType/create-pool"
  // url: "/college-basketball/conference-tournament/bracket/create-pool"
  const { path, params, url } = match
  const matcher = path.split("/:gameType")[0] + "/:gameType"
  const values = url.split(params.gameType)[0] + params.gameType
  return {
    matcher,
    values,
  }
}

function shouldGoToNextStepInUpdateFor(formStep: string) {
  return /round-bonus-settings|conferences/.test(formStep)
}

type RoutesType = TPoolRouteProps["routes"]
type RoutesPropType = {
  routes: RoutesType
}

class PoolSetup extends React.PureComponent<IPoolSetupProps & RoutesPropType> {
  public switchFormStep: TSwitchStep = (formStepOrHack, poolId, event, poolUrl, usePoolUrl) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    const { history, location, match, isUpdate, entry, seasonType, gameType, gameInstanceUid, poolData } = this.props
    const stepMappings = stepMappingFor(gameInstanceUid, seasonType, gameType)
    const parts = (formStepOrHack || "").split(toggleEditActionName)
    const attribute = parts.length > 1 ? parts[1] : null
    const foundStepMapping = (attribute && stepMappings.find(({ field }) => field === attribute)) || undefined
    const stepName = (foundStepMapping && foundStepMapping.path) || attribute
    const { deviceType } = poolData || {}
    const isMobile = deviceType === "handheld"
    const formStep = stepName || formStepOrHack
    const shouldGoToNextStepInUpdate = shouldGoToNextStepInUpdateFor(formStep || "")
    const isUpdateOrEditAndNextStep = isUpdate && shouldGoToNextStepInUpdate && !!formStep && !!stepMappings.find(({ path }) => path === formStep)
    const hasStep = stepName === "name" || !!foundStepMapping || isUpdateOrEditAndNextStep
    // console.log(`attribute: ${attribute} -> ${stepName} ${hasStep}`, stepMappings)
    // console.log(`switchFormStep: formStepOrHack: ${formStepOrHack} (${shouldGoToNextStepInUpdate}, ${hasStep}: ${!!foundStepMapping}, ${isUpdateOrEditAndNextStep})`);
    // console.log(`attribute: ${attribute} (${location.pathname})`)
    // console.log(entry)
    // console.log(location.pathname)
    // const newState = Object.assign({}, location.state || {}, { formStep })
    // history.push(location.pathname, newState)
    if (isUpdate && entry) {
      const basePath = withoutDomain(entry.pool.url || poolUrl || "")
      // return;
      if ((attribute || shouldGoToNextStepInUpdate) && hasStep) {
        history.push(`${basePath}/settings/attribute/${formStep}`)
      } else {
        history.push(`${basePath}/settings`)
      }
      // if (attribute === financesActionName) {
      //   // history.push(`${basePath}/settings/finances`);
      //   // console.log(`HERE!`)
      //   // const currentStepOverride = location.state && location.state.currentStep === financesActionName;
      //   // history.push(location.pathname, currentStepOverride ? null : { currentStep: financesActionName });
      // } else if (formStep === "round-bonus-settings") {
      //   // allow for updating round bonuses after selecting the type
      //   history.push(`${basePath}/settings/attribute/${formStep}`);
      // } else if (attribute) {
      //   if (hasStep) {
      //     history.push(`${basePath}/settings/attribute/${stepName}`);
      //   } else {
      //     // console.log(`missed: ${stepName} ${attribute} ${formStep}`);
      //     // TODO qac: need to fix this, its a hack for updating but not having to have the individual choices
      //     history.push(`${basePath}/settings/attribute/weights-and-bonuses`);
      //   }
      // } else {
      //   // TODO qac: why is this not working?
      //   if (parts.length && parts[0] === "conferences") {
      //     history.push(`${basePath}/settings/attribute/conferences`);
      //   } else {
      //     history.push(`${basePath}/settings`);
      //   }
      // }
    } else {
      if (isMobile) {
        history.push(constants.CREATE_POOL_ROAD_BLOCK_PATH.replace("{POOL_ID}", poolId))
      } else {
        const vals = getCreatePoolParams(location.pathname, location.search)
        const isInFinilizedUpdateFlow = vals.hasFinalizedSetupFlag || hasStep
        const search = isInFinilizedUpdateFlow ? `?${vals.createPoolFinishedSetupSearchKey}=true` : ""
        // console.dir(vals)
        if (!isInFinilizedUpdateFlow && typeof window !== "undefined") {
          window.scrollTo(0, 0)
        }
        const parts2 = location.pathname.split("/pool/")
        // this prevents landing routes from breaking the flow (user arrives at /create-pool/?arg=this)
        const rootPart = parts2[0].replace(/\/$/, "")
        const shouldReturnToRecapInUpdateFlow =
          isInFinilizedUpdateFlow && vals.formStep && !shouldGoToNextStepInUpdate && !/recap|invite/.test(vals.formStep)
        const formStepOrRecap = shouldReturnToRecapInUpdateFlow ? "recap" : formStep
        if (formStepOrRecap) {
          const path = `${rootPart}/pool/${poolId}/step/${formStepOrRecap}${search}`
          const stepOrder = stepMappings.map(stepToPath)
          if (!stepOrder) {
            throw new Error("sfoiajwef")
          }
          if (stepOrder.indexOf(formStepOrRecap) === 0 || shouldReturnToRecapInUpdateFlow) {
            // NOTE qac: for the first step (name the pool), use replace so the back button doesnt take us there
            history.replace(path, slide.state)
          } else {
            history.push(path, slide.state)
          }
        } else {
          // steps are done!
          const isChallengeRoute = /\/challenge/.test(location.pathname)
          if (!isChallengeRoute) {
            const pUrl = usePoolUrl ? poolUrl || entry?.pool.url : entry?.pool.url || poolUrl
            if (pUrl) {
              history.push(withoutDomain(pUrl))
            } else {
              if (typeof window !== "undefined") {
                window.SH_ERROR_NOTIFY(new Error(`Didnt refetch query...`))
              }
              const url = `${getPathPrefix(match).values}/pools/${poolId}`
              window.location.replace(url)
            }
          }
        }
      }
    }
  }

  public onPoolMutationComplete = (response) => {
    const poolPeriodQuery = this.props.poolPeriodQuery as any
    // console.log(response, poolPeriodQuery);
    const queryAssumesCustom = poolPeriodQuery.data?.gameInstance.period.usesEventGroups
    const newUsesCustom = response.upsertPool?.pool.poolSettings.gamesPerPeriod === "CUSTOM"
    const needToCheckForTab = queryAssumesCustom !== newUsesCustom
    // const needToUpdateTiebreaker =
    // console.log(`custom changing! ${newUsesCustom} != ${queryAssumesCustom}`)
    if (poolPeriodQuery && poolPeriodQuery.refetch && needToCheckForTab) {
      // NOTE qac: we probably dont need to worry about replica lag
      poolPeriodQuery.refetch()
    }
  }

  public render() {
    const {
      location,
      history,
      entry,
      seasonId,
      match,
      gameType,
      seasonType,
      gameInstanceUid,
      year,
      isUpdate = false,
      canEdit = false,
      areaHasMultipleEntriesPerUser,
      poolData,
    } = this.props
    // const matchRegex = formMatcher.exec(location.pathname);
    // const poolId = matchRegex && matchRegex[1];
    // const formStep = matchRegex && matchRegex[2];

    const { pathname, search } = location
    const vals = getCreatePoolParams(pathname, search)
    const { hasFinalizedSetupFlag } = vals
    const poolId = vals.poolId || (entry && entry.pool && entry.pool.id) || this.props.poolId
    const formStep = vals.formStep

    const { matcher } = getPathPrefix(match)
    const defaultParams = { gameType, seasonType, year }
    const variables = { poolId: poolId || "" }
    // console.log(`PoolSetup: ${matcher} ${canEdit}`, vals, match, matcher, stepOrder)
    // console.log(stepOrder.map((stepName) => `${matcher}/pools/:poolId/settings/attribute/${stepName}`))
    const { productSeason, deviceType } = poolData || {}
    const createPoolPageDisclaimer = buildDisclaimer(gameInstanceUid || "", seasonType, productSeason?.productAbbrev || "", year)
    return (
      <ToggleNexusTheme isNexusStyles={isUpdate}>
        <Query<PoolDetailsQuery, PoolDetailsQueryVariables> skip={!variables.poolId} variables={variables} query={POOL_DETAILS_QUERY}>
          {(poolDetailQuery) => (
            <Mutation<UpsertPoolMutation, UpsertPoolMutationVariables>
              mutation={UPSERT_POOL_MUTATION}
              onCompleted={this.onPoolMutationComplete}
              awaitRefetchQueries
              refetchQueries={["CentralCurrentUsersEntriesQuery"]}
              fetchPolicy="no-cache"
            >
              {(upsertPoolMutation, { loading, error }) => {
                const pool = (poolDetailQuery.data && poolDetailQuery.data.pool) || null
                const stepMappings = stepMappingFor(gameInstanceUid, seasonType, gameType, pool || undefined)
                const stepOrder = stepMappings.map(stepToPath)
                const currentStepOverride = (location.state || ({} as any)).currentStep
                const isReactivatedPool = !!pool && !!pool.parentPoolId
                if (isUpdate) {
                  // console.groupCollapsed(`PoolSetup:`)
                  // console.dir(poolDetailQuery && poolDetailQuery.data && poolDetailQuery.data.pool)
                  // console.dir(entry)
                  // console.dir(pool)
                  // console.groupEnd()
                  if (!pool || !entry) {
                    return <LoadingView />
                  }

                  const hasManagerRole = entryHasManagerRole(entry)
                  if (isMarchMadnessMatcher.test(entry.gameInstanceUid) && !hasManagerRole) {
                    const basePath = withoutDomain(entry.pool.url || pool.url || "")
                    history.replace(basePath)
                  }

                  const classNames = [
                    `fill-hack__${/attribute\/|\/settings/.test(location.pathname)}`,
                    `is-update--${isUpdate}`,
                    areaHasMultipleEntriesPerUser ? "is-bracket" : "",
                  ]
                  return (
                    <PoolFormContainer className={classNames.join(" ")}>
                      <Switch location={location}>
                        <Route
                          exact={true}
                          path={`${matcher}/:poolId/settings`}
                          render={(_props) => {
                            if (loading) {
                              return <LoadingView />
                            }
                            if (areaHasMultipleEntriesPerUser) {
                              return (
                                <BracketPoolSettingsForm
                                  canEdit={canEdit}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}
                                  mutation={upsertPoolMutation}
                                  pool={pool}
                                  poolData={this.props.poolData}
                                  routes={this.props.routes}
                                  error={error}
                                />
                              )
                            }
                            return (
                              <PoolSetupStepRecap
                                switchStep={this.switchFormStep}
                                currentStep={currentStepOverride || "recap"}
                                pool={pool}
                                mutation={upsertPoolMutation}
                                entryId={entry.id}
                                entryName={entry.name}
                                isUpdate={isUpdate}
                                canEdit={canEdit}
                                {...defaultParams}
                              />
                            )
                          }}
                        />
                        <Route
                          exact={true}
                          path={`${matcher}/pools/:poolId/settings`}
                          render={(_props) => {
                            if (loading) {
                              return <LoadingView />
                            }
                            if (areaHasMultipleEntriesPerUser) {
                              return (
                                <BracketPoolSettingsForm
                                  canEdit={canEdit}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}
                                  mutation={upsertPoolMutation}
                                  pool={pool}
                                  poolData={this.props.poolData}
                                  routes={this.props.routes}
                                  error={error}
                                />
                              )
                            }
                            return (
                              <PoolSetupStepRecap
                                switchStep={this.switchFormStep}
                                currentStep={currentStepOverride || "recap"}
                                pool={pool}
                                mutation={upsertPoolMutation}
                                entryId={entry.id}
                                entryName={entry.name}
                                isUpdate={isUpdate}
                                canEdit={canEdit}
                                {...defaultParams}
                              />
                            )
                          }}
                        />
                        <Route
                          exact={true}
                          path={`${matcher}/pools/:poolId/settings/attribute/name`}
                          render={(_props) => (
                            <CreatePoolForm
                              switchStep={this.switchFormStep}
                              mutation={upsertPoolMutation}
                              seasonId={seasonId}
                              isUpdate={isUpdate}
                              poolId={pool.id}
                              initialName={pool.name}
                              initialPassword={pool.password}
                              {...defaultParams}
                            />
                          )}
                        />
                        {canEdit &&
                          stepOrder.map((stepName) => (
                            <Route
                              key={stepName}
                              exact={true}
                              path={`${matcher}/pools/:poolId/settings/attribute/${stepName}`}
                              render={(_props) => {
                                return (
                                  <PoolSetupStepForm
                                    switchStep={this.switchFormStep}
                                    currentStep={stepName}
                                    pool={pool}
                                    mutation={upsertPoolMutation}
                                    entryId={entry.id}
                                    entryName={entry.name}
                                    isUpdate={isUpdate}
                                    canEdit={canEdit}
                                    {...defaultParams}
                                  />
                                )
                              }}
                            />
                          ))}
                        <Route component={NotFound} />
                      </Switch>
                      {error && <ErrorView error={error} />}
                    </PoolFormContainer>
                  )
                }
                const isInFlow = !isReactivatedPool && !hasFinalizedSetupFlag
                const usesAnimation = !this.props.useBodyScroll && isInFlow
                const isNcaaw = isNCAAWTournamentMatcher.test(gameInstanceUid ?? "")
                if (areaHasMultipleEntriesPerUser) {
                  // Brackets pool setup
                  const initialOpenInvites = (pool?.poolSettings.__typename === "PoolSettings" && pool.poolSettings.openInvites) || false
                  return (
                    <PoolFormContainer className="white-bkg">
                      <PoolFormTransitionContainer className={`uses-animation--${usesAnimation}`}>
                        <Transitions
                          pageKey={location.pathname}
                          {...location.state}
                          reversed={history.action === "POP"}
                          noTransition={!usesAnimation}
                        >
                          <Switch location={location}>
                            <Route
                              exact={true}
                              path={`${matcher}/create-pool`}
                              render={(_props) => (
                                <BracketPoolSetupCommon
                                  currentStep="info"
                                  steps={stepMappings}
                                  disclaimer={createPoolPageDisclaimer}
                                  isNcaaw={isNcaaw}
                                >
                                  <AnalyticScreen feature="brackets" subfeature={`create-pool`} title={`Create Pool`} deviceType={deviceType} />
                                  <CreateBracketPoolForm
                                    switchStep={this.switchFormStep}
                                    mutation={upsertPoolMutation}
                                    nextStep={stepOrder ? stepOrder[1] : undefined}
                                    seasonId={seasonId}
                                    {...defaultParams}
                                  />
                                </BracketPoolSetupCommon>
                              )}
                            />
                            <Route
                              exact={true}
                              path={`${matcher}/create-pool/pool/:poolId/step/name`}
                              render={(_props) => {
                                if (!pool || !entry) {
                                  return <LoadingView />
                                }
                                return (
                                  <BracketPoolSetupCommon
                                    steps={stepMappings}
                                    currentStep="info"
                                    disclaimer={createPoolPageDisclaimer}
                                    isNcaaw={isNcaaw}
                                  >
                                    <CreateBracketPoolForm
                                      switchStep={this.switchFormStep}
                                      mutation={upsertPoolMutation}
                                      seasonId={seasonId}
                                      isUpdate={true}
                                      isInPromoVersion={true}
                                      poolId={pool.id}
                                      initialName={pool.name}
                                      initialUsesMagicLink={pool.usesMagicLink}
                                      initialOpenInvites={initialOpenInvites}
                                      initialSlogan={pool.slogan ?? undefined}
                                      initialPassword={pool.password}
                                      {...defaultParams}
                                    />
                                  </BracketPoolSetupCommon>
                                )
                              }}
                            />
                            {stepOrder.map((stepName) => (
                              <Route
                                key={stepName}
                                exact={true}
                                path={`${matcher}/create-pool/pool/:poolId/step/${stepName}`}
                                render={(_props) => {
                                  if (!pool || !entry || (hasFinalizedSetupFlag && loading)) {
                                    return <LoadingView />
                                  }
                                  return (
                                    <BracketPoolSetupCommon
                                      currentStep={stepName}
                                      steps={stepMappings}
                                      disclaimer={createPoolPageDisclaimer}
                                      isNcaaw={isNcaaw}
                                    >
                                      <AnalyticScreen
                                        feature="brackets"
                                        subfeature={`create-${stepName === "game-weights" ? "scoring" : stepName}`}
                                        title={`Create Pool - ${stepName === "game-weights" ? "Scoring" : stepName}`}
                                        deviceType={deviceType}
                                      />
                                      <PoolSetupStepForm
                                        switchStep={this.switchFormStep}
                                        currentStep={stepName}
                                        pool={pool}
                                        mutation={upsertPoolMutation}
                                        entryId={entry.id}
                                        entryName={entry.name}
                                        {...defaultParams}
                                        {...this.props}
                                      />
                                    </BracketPoolSetupCommon>
                                  )
                                }}
                              />
                            ))}
                            <Route component={NotFound} />
                          </Switch>
                        </Transitions>
                      </PoolFormTransitionContainer>
                    </PoolFormContainer>
                  )
                }
                return (
                  <PoolFormContainer>
                    <FixedBgScreen />
                    {(usesAnimation && <slide.GlobalStyle />) || null}
                    <PoolSetupTopUi className="top-ui-container">
                      {false && <MobileBackBtn />}
                      {isInFlow && <PoolFormStepper percent={getFormPercent(formStep, pool)} />}
                    </PoolSetupTopUi>
                    <PoolFormTransitionContainer className={`uses-animation--${usesAnimation}`}>
                      <Transitions pageKey={location.pathname} {...location.state} reversed={history.action === "POP"} noTransition={!usesAnimation}>
                        <Switch location={location}>
                          <Route
                            exact={true}
                            path={`${matcher}/create-pool`}
                            render={(_props) => (
                              <CreatePoolForm switchStep={this.switchFormStep} mutation={upsertPoolMutation} seasonId={seasonId} {...defaultParams} />
                            )}
                          />
                          <Route
                            exact={true}
                            path={`${matcher}/create-pool/pool/:poolId/step/name`}
                            render={(_props) => {
                              if (!pool || !entry) {
                                return <LoadingView />
                              }
                              return (
                                <CreatePoolForm
                                  switchStep={this.switchFormStep}
                                  mutation={upsertPoolMutation}
                                  seasonId={seasonId}
                                  isUpdate={true}
                                  isInPromoVersion={true}
                                  poolId={pool.id}
                                  initialName={pool.name}
                                  initialPassword={pool.password}
                                  {...defaultParams}
                                />
                              )
                            }}
                          />
                          {stepOrder.map((stepName) => (
                            <Route
                              key={stepName}
                              exact={true}
                              path={`${matcher}/create-pool/pool/:poolId/step/${stepName}`}
                              render={(_props) => {
                                if (!pool || !entry || (hasFinalizedSetupFlag && loading)) {
                                  return <LoadingView />
                                }
                                return (
                                  <PoolSetupStepForm
                                    switchStep={this.switchFormStep}
                                    currentStep={stepName}
                                    pool={pool}
                                    mutation={upsertPoolMutation}
                                    entryId={entry.id}
                                    entryName={entry.name}
                                    canEdit={canEdit}
                                    {...defaultParams}
                                  />
                                )
                              }}
                            />
                          ))}
                          <Route component={NotFound} />
                        </Switch>
                      </Transitions>
                    </PoolFormTransitionContainer>
                  </PoolFormContainer>
                )
              }}
            </Mutation>
          )}
        </Query>
      </ToggleNexusTheme>
    )
  }
}

function PoolSetupHoc(props: TPoolRouteProps) {
  const { poolData } = props
  const {
    detailedPeriod,
    detailedEntry,
    allCurrentEntries,
    isIosBrowser,
    isCbsAppWebview,
    poolId,
    poolPeriodQuery,
    hasManagerRole,
    allSegments,
    areaHasMultipleEntriesPerUser,
  } = poolData

  const segment = (detailedPeriod && allSegments.find((seg) => seg.id === detailedPeriod.segment.id)) || undefined
  if (!segment) {
    return <LoadingView />
  }
  const mergedPoolSettings = extractPoolOgProps(poolData)
  const { location } = props
  const poolIdT = poolId || getCreatePoolParams(location.pathname, location.search).poolId
  const season = segment.season
  const seasonId = season.id
  const isUpdate = !poolCreateRegexp.test(location.pathname)
  const entry = allCurrentEntries.find((etr) => etr.pool.id === poolIdT) || detailedEntry || placeholderEntry
  const canEdit = hasManagerRole
  const gameType = segment.gameType
  return (
    <PoolSetup
      entry={entry as CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node}
      seasonId={seasonId}
      poolPeriodQuery={poolPeriodQuery}
      isUpdate={isUpdate}
      canEdit={canEdit}
      gameType={gameType}
      useBodyScroll={isIosBrowser && isCbsAppWebview}
      areaHasMultipleEntriesPerUser={areaHasMultipleEntriesPerUser}
      routes={props.routes}
      {...props}
      {...mergedPoolSettings}
    />
  )
}

export default PoolSetupHoc
