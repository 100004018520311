import styled from "styled-components/macro"
import { fontWeight, pxToRem } from "../../../utils/style-utils"
import { poolSettingsRowW } from "../styles/CommonPoolSetup.styles"

// export const PickLockCenterImg = styled(BackgroundImage)`
// background-image: url(${formStepPickDeadline});
// height: 14vh;
// width: 100%;
// max-width: ${pxToRem(320)};
// margin: 0 auto;
// flex: 1;
// @media (max-width: ${pxToRem(layout.formHiddenLabelsW)}) {
//   height: 30vh;
//   max-height:
//   max-width: 60%;
//   background-image: url(${formStepPickDeadlineMobile});
// }
// `;

// @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
//   height: ${pxToRem(300)};
//   position: absolute;
//   top: ${pxToRem(200)};
//   left: 50%;
//   margin-left: ${pxToRem(-480)};
//   font-size: ${pxToRem(200)};
//   position: static;
//   height: 20vh;
//   max-width: ${pxToRem(320)};
// }

export const SettingsH3 = styled.h3`
  position: relative;
  text-transform: uppercase;
  letter-spacing: ${pxToRem(4)};
  font-size: ${pxToRem(20)};
  font-weight: ${fontWeight.semiBold};
  text-align: center;
  max-width: ${pxToRem(poolSettingsRowW + 16 * 2)};
  width: 100%;
  margin: 0 auto;
  padding: ${pxToRem(6)} ${pxToRem(16)} ${pxToRem(16)};
`
export const SettingsP = styled.p`
  width: 100%;
  max-width: ${pxToRem(poolSettingsRowW + 16 * 2)};
  padding: 0 ${pxToRem(16)} ${pxToRem(16)};
  line-height: ${pxToRem(21)};
`

export const ConstitutionContainer = styled.div`
  position: relative;
  flex: 2 0 auto;
  & > h3 {
    padding-right: ${pxToRem(24)};
  }
  & .toggle-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    &.is-toggled--false svg {
      height: ${pxToRem(16)};
      width: ${pxToRem(16)};
    }
    &.is-toggled--true {
      padding: 0;
    }
  }
`
interface IConstitutionForm {
  disabled: boolean
}
export const ConstitutionForm = styled.form<IConstitutionForm>`
  width: 100%;
  display: block;
  padding: ${pxToRem(10)};
  & p {
    margin: 0 auto;
    &.has-value--false {
      text-align: center;
      opacity: 0.8;
    }
  }
  & textarea {
    width: 100%;
    max-width: ${pxToRem(450)};
    margin: 0 auto;
    display: block;
    padding: ${pxToRem(15)};
    border-radius: ${pxToRem(8)};
    height: 0;
    color: currentColor;
    background-color: ${({ theme }) => (theme.isNexusStyles ? "rgba(255,255,255,0.8)" : "rgba(255,255,255,0.2)")};
    min-height: ${pxToRem(250)};
    transition: background-color 0.4s ease, min-height 0.4s ease;
    &[disabled] {
      background-color: transparent;
      min-height: 0;
      padding: 0;
    }
    &::placeholder {
      opacity: 0.8;
      color: currentColor;
      text-align: center;
    }
  }
  & button {
    margin: ${pxToRem(15)} auto;
  }
  &[disabled] button {
    display: none !important;
  }
`
export const PoolSetupBtnsContainer = styled.div`
  flex: 1 0 20%;
`
