import { Field } from "formik"
import React from "react"
// import constants from "../../../../common/constants";
import { MaxPicksPerPeriodCountTypes } from "../../../../common/pool-settings"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import NexusSelect from "../../../shared/molecules/Form/Select"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepPicksCapStyles } from "../styles/CommonPoolSetup.styles"
import PoolSetupDesktopDescription from "./PoolSetupDesktopDescription"

const PoolSetupStepPicksCap = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  // console.log('PoolSetupStepRoundBonuses')
  // console.dir(props);
  // console.dir(MaxPicksPerPeriodCountTypes)
  const { isUpdate, choices, field } = props
  const choice = choices && choices.length ? choices[0] : undefined
  const describedById = (choice && choice.description && `${field}-${choice.value}-db`) || undefined
  return (
    <PoolSetupStepPicksCapStyles style={{ flex: "0 0 auto" }}>
      <AnalyticScreen
        feature={`${isUpdate ? "initial " : ""}setup`}
        subfeature={"set picks cap"}
        title={`${isUpdate ? "Initial " : ""}Setup - Set Number of games`}
      />
      <div>
        <Field name={field} component={NexusSelect} defaultValue="" ariaDescribedby={describedById} options={MaxPicksPerPeriodCountTypes} />
        <FormButton as="button" type="submit">
          Submit
        </FormButton>
      </div>
      <PoolSetupDesktopDescription {...props} />
    </PoolSetupStepPicksCapStyles>
  )
}

export default PoolSetupStepPicksCap
