import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { Form, Formik, FormikProps } from "formik"
import * as React from "react"
import { getSegment, getFullPoolSettings } from "../../../../common/pool-settings-setup"
import { Logo } from "../../../components/Logos"
import StepHeader from "../components/StepHeader"
import { nextStepFor, stepMappingFor, weightsAndRoundsField } from "../components/StepMappings"
import StepMobileHelper from "../components/StepMobileHelper"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { InviteStepCustomContainer, StyledForm } from "../styles/CommonPoolSetup.styles"

const FrontendOnlyFields: string[] = [
  // "format",
]

const emptyArray = [] as never[]

const isDiff = (v1, v2) => JSON.stringify(v1) !== JSON.stringify(v2)

class PoolSetupStepForm extends React.PureComponent<IPoolSetupStepProps> {
  public getFullPoolSettings() {
    return getFullPoolSettings(this.props.pool)
  }

  public getDefaultPoolSettings() {
    return getFullPoolSettings(this.props.pool, true)
  }

  public getInitialValues = () => {
    const poolSettings = this.getFullPoolSettings()
    // const tournamentIds = poolSettings.tournamentIds || [];
    // const format = tournamentIds.length > 1 && "MULTIPLE_TOURNAMENTS" || tournamentIds.length === 1 && "SINGLE_TOURNAMENT" || "NONE";
    const currentPoolSettings = Object.assign(
      {
        // format,
        // constitution: this.props.pool.constitution || "",
      },
      poolSettings,
    )
    const rb = currentPoolSettings.roundBonuses || []
    if (!rb.length) {
      currentPoolSettings.roundBonuses = this.getDefaultPoolSettings().roundBonuses
    }
    // initialize roundBonuses
    // NOTE qac: the form ALWAYS has these, but persisting logic controls what ends up in the server
    // if (currentPoolSettings.hasOwnProperty("roundBonuses")) {
    //   const roundBonuses = currentPoolSettings.roundBonuses;
    //   segment.periods.edges.forEach((e, i) => {
    //     if (roundBonuses.length <= i) {
    //       roundBonuses.push(i + 1);
    //     }
    //   });
    // }
    // console.log(`getInitialValues:`)
    // console.dir(currentPoolSettings)
    return currentPoolSettings
  }

  public onSubmit = async (values: IPoolSetupStepFormValues, actions: any) => {
    const { currentStep, pool, switchStep, mutation, isUpdate, seasonType, gameType } = this.props
    const stepMappings = stepMappingFor(pool.gameInstanceUid, seasonType, gameType, pool)
    // const stepOrder = stepMappings.map(stepToPath);
    const stepMapping = stepMappings.find((m) => m.path === currentStep)
    const field: string = (stepMapping && stepMapping.field) || ""
    // console.groupCollapsed("onSubmit")
    // console.log(`"onSubmit" ${currentStep}`);
    // console.dir(this.props);
    // console.dir(values);
    // console.dir(actions);
    // console.dir(stepMapping)
    if (field) {
      let value
      const currentPoolSettings = this.getFullPoolSettings()
      const updatedPoolSettings = {} as any
      if (field === weightsAndRoundsField) {
        if (isDiff(currentPoolSettings.gameWeightType, values.gameWeightType)) {
          updatedPoolSettings.gameWeightType = values.gameWeightType
        }
        if (isDiff(currentPoolSettings.roundBonusType, values.roundBonusType)) {
          updatedPoolSettings.roundBonusType = values.roundBonusType
        }
      } else if (field === "format") {
        value = values[field]
        const tournamentIds = currentPoolSettings.tournamentIds || emptyArray
        const segmentPoolSettings = getSegment(pool).poolSettings
        if (segmentPoolSettings.__typename !== "PoolSettings") {
          throw new Error(`Invalid pool settings ${segmentPoolSettings.__typename}`)
        }
        const baseIds = segmentPoolSettings.tournamentIds || emptyArray
        if (values.format === "MULTIPLE_TOURNAMENTS" && tournamentIds.join(",") !== baseIds.join(",")) {
          updatedPoolSettings.tournamentIds = segmentPoolSettings.tournamentIds
          // console.log(`settings ${tournamentIds.join(",")} !== ${segmentPoolSettings.tournamentIds.join(",")}`)
        }
        if (values.format === "SINGLE_TOURNAMENT" && tournamentIds.length !== 1) {
          updatedPoolSettings.tournamentIds = [baseIds[0]]
        }
      } else {
        value = values[field]
        if (!FrontendOnlyFields.includes(field) && isDiff(currentPoolSettings[field], value)) {
          updatedPoolSettings[field] = value
        }
        // await setValueSubmit(this.props);
      }
      // console.dir(currentPoolSettings)
      // console.log(`changes: ${JSON.stringify(updatedPoolSettings)} (from: ${JSON.stringify(currentPoolSettings)})`)
      // NOTE qac: this is where we remove the roundBonuses based on roundBonusType (IF WE NEED TO)
      const roundBonuses = currentPoolSettings.roundBonuses || []
      if (updatedPoolSettings.roundBonusType === "NONE" && roundBonuses.length) {
        updatedPoolSettings.roundBonuses = []
      }
      if (updatedPoolSettings.roundBonusType === "STANDARD" && roundBonuses.length < 1) {
        updatedPoolSettings.roundBonuses = values.roundBonuses
      }
      // make sure maxPicksPerPeriodCount is a number
      if (typeof updatedPoolSettings.maxPicksPerPeriodCount === "string") {
        updatedPoolSettings.maxPicksPerPeriodCount = updatedPoolSettings.maxPicksPerPeriodCount
          ? Number(updatedPoolSettings.maxPicksPerPeriodCount)
          : null
      }
      // console.dir(pool);
      // console.dir(updatedPoolSettings);
      // console.dir(currentPoolSettings);
      // console.dir(values);
      const nextStep = nextStepFor(currentStep, pool, value, Object.assign({}, currentPoolSettings, updatedPoolSettings))
      // console.log(`setValue: ${currentStep} -> ${nextStep} (${value}) (${isUpdate})`)//, this.props)
      const isUpdateOverride = isUpdate && currentStep === "game-weights" && "nothing"
      switchStep(isUpdateOverride || nextStep, pool.id)
      // console.groupEnd()
      if (Object.keys(updatedPoolSettings).length) {
        const variables = {
          poolId: pool.id,
          seasonId: pool.season.id,
          poolSettings: updatedPoolSettings,
        }
        await mutation({ variables })
        // console.log(`resp:`)
        // console.dir(resp)
      }
      // switchStep(nextStep, pool.id, event)
    } else {
      console.warn(`unknown submit step: ${currentStep}`)
    }
  }

  // validate = async (values, props) => {
  //   console.log('handleChange')
  //   console.dir(values)
  //   console.dir(props)
  // }

  // onLabelChange = async (event: React.ChangeEvent<any>) => {
  //   console.log('onLabelChange')
  //   console.dir(event)
  //   console.dir(this.props)
  // }

  public LogoEl = () => {
    const { pool } = this.props
    if (!pool) {
      return null
    }
    return <Logo style={{ margin: "2vh 0", maxHeight: "11.25rem" }} gameInstanceUid={pool.gameInstanceUid} season={pool.season} />
  }

  public render() {
    // console.log(`PoolSetupStepForm props`);
    // console.dir(this.props);
    const { pool, seasonType, gameType, areaHasMultipleEntriesPerUser } = this.props
    const values = this.getInitialValues()
    const stepMappings = stepMappingFor(pool.gameInstanceUid, seasonType, gameType, pool)
    const stepMapping = stepMappings.find((m) => m.path === this.props.currentStep)
    // console.dir(stepMapping)
    if (!stepMapping) {
      return null
    }
    const { Component, ...rest } = stepMapping
    const isReactivatedPool = !!pool && !!pool.parentPoolId
    if (["recap", "invite"].includes(stepMapping.path)) {
      const isReinvite = stepMapping.path === "invite"
      const title = isReactivatedPool
        ? isReinvite
          ? `Select the members you want to invite this season`
          : `Welcome to the New Season!`
        : stepMapping.title
      const subtitle = isReactivatedPool ? (isReinvite ? `Re-Invite Members` : `Pool Reactivated`) : stepMapping.subtitle
      const CenterImg = isReactivatedPool ? (isReinvite ? null : this.LogoEl) : stepMapping.CenterImg
      const isInviteStepCustomContainer = isReinvite && isReactivatedPool
      const className = cx({
        [`custom-step--${stepMapping.path}`]: !areaHasMultipleEntriesPerUser,
      })
      const showMobileDivider = false
      if (isInviteStepCustomContainer) {
        return (
          <InviteStepCustomContainer as={"div" as any} className={className}>
            {!areaHasMultipleEntriesPerUser && (
              <StepHeader CenterImg={CenterImg} showMobileDivider={showMobileDivider} title={title} subtitle={subtitle} />
            )}
            {Component && <Component {...this.props} {...rest} />}
            <StepMobileHelper pool={pool} values={values} sections={stepMapping.choices || emptyArray} />
          </InviteStepCustomContainer>
        )
      }
      return (
        <StyledForm as={"div" as any} className={className}>
          {!areaHasMultipleEntriesPerUser && (
            <StepHeader CenterImg={CenterImg} showMobileDivider={showMobileDivider} title={title} subtitle={subtitle} />
          )}
          {Component && <Component {...this.props} {...rest} />}
          <StepMobileHelper pool={pool} values={values} sections={stepMapping.choices || emptyArray} />
        </StyledForm>
      )
    }
    return (
      <Formik
        initialValues={values}
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        component={(formikBag: FormikProps<IPoolSetupStepFormValues>) => {
          // console.log(`"formikBag" '${formikBag.values.format}'`);
          // console.dir(formikBag);
          const title = isReactivatedPool && stepMapping.path === "invite" ? `Notify the members of the new challenge` : stepMapping.title
          const subtitle = isReactivatedPool && stepMapping.path === "invite" ? `Re-invite Members` : stepMapping.subtitle
          return (
            <StyledForm as={Form}>
              {!areaHasMultipleEntriesPerUser && <StepHeader CenterImg={stepMapping.CenterImg} title={title} subtitle={subtitle} />}
              {Component && <Component {...this.props} {...rest} {...formikBag} />}
              <StepMobileHelper pool={pool} values={formikBag.values} sections={stepMapping.choices || emptyArray} />
            </StyledForm>
          )
        }}
      />
    )
  }
}
export default PoolSetupStepForm
