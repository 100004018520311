import React from "react"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { PoolSetupStepFormColDesktopDescription } from "../styles/CommonPoolSetup.styles"

const PoolSetupDesktopDescription = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  // console.log('PoolSetupStepRoundBonuses')
  // console.dir(props);
  // console.dir(MaxPicksPerPeriodCountTypes)
  const { choices, field, pool, values } = props
  const choice = choices && choices.length ? choices[0] : ({} as never)
  const description = (choice.bodyFunc && choice.bondFunc(pool, values)) || choice.description || undefined
  if (!description) {
    return null
  }
  const describedById = `${field}-${choice.value}-db` || undefined
  return <PoolSetupStepFormColDesktopDescription id={describedById}>{description}</PoolSetupStepFormColDesktopDescription>
}

export default PoolSetupDesktopDescription
