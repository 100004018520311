import React from "react"
import { PoolFormDesktopHr, PoolFormh2, PoolFormh3, PoolFormMobileHr, StepHeaderContainer } from "../styles/CommonPoolSetup.styles"

export const StepHeader = (props) => {
  const { title, subtitle, CenterImg, showMobileDivider, ...rest } = props
  const shrink = subtitle === "Pool Name" || CenterImg === null
  return (
    <StepHeaderContainer shrink={shrink} {...rest}>
      <PoolFormh3 as="h3">{subtitle}</PoolFormh3>
      <PoolFormh2 as="h2">{title}</PoolFormh2>
      {showMobileDivider === undefined ? <PoolFormMobileHr /> : null}
      {CenterImg === null ? null : CenterImg ? <CenterImg className="img" /> : <PoolFormDesktopHr />}
    </StepHeaderContainer>
  )
}

export default StepHeader
