import styled from "styled-components/macro"
import { ButtonWithAfterContentInteraction } from "../../../components/Button"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const TournamentBtn = styled(ButtonWithAfterContentInteraction)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  min-width: ${pxToRem(100)};
  max-width: ${pxToRem(170)};
  height: ${pxToRem(100)};
  background: black;
  color: #fff;
  margin: ${pxToRem(6)};
  font-weight: ${fontWeight.bold};
  transition: all 0.2s ease-out;
  text-transform: uppercase;
  & svg {
    transform: scale(0);
    transition: all 0.2s ease-out;
    height: 2em;
    width: 0;
    margin-right: 0.25em;
  }
  &.is-selected--true {
    background-color: ${palette.uiBlue1};
  }
  &.is-selected--true svg {
    transform: scale(1);
    width: 2em;
  }
  @media (max-width: 375px) {
    & {
      flex: 1 1 ${pxToRem(120)};
    }
  }
`
export const TournamentBtns = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10vh;
`
