import { Field } from "formik"
import React from "react"
import constants from "../../../../common/constants"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepPicksCapStyles } from "../styles/CommonPoolSetup.styles"
import PoolSetupDesktopDescription from "./PoolSetupDesktopDescription"

const PoolSetupStepMaxEntries = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  // console.log('PoolSetupStepRoundBonuses')
  // console.dir(props);
  // console.dir(MaxPicksPerPeriodCountTypes)
  const { isUpdate, field } = props
  return (
    <PoolSetupStepPicksCapStyles>
      <AnalyticScreen
        feature={`${isUpdate ? "initial " : ""}setup`}
        subfeature={"set max entries"}
        title={`${isUpdate ? "Initial " : ""}Setup - Set Max number of Entries`}
      />
      <div>
        <Field name={field} type="number" min={1} max={constants.POOL_MAX_ENTRIES_PER_USER} />
        <FormButton type="submit">Submit</FormButton>
      </div>
      <PoolSetupDesktopDescription {...props} />
    </PoolSetupStepPicksCapStyles>
  )
}

export default PoolSetupStepMaxEntries
