import styled from "styled-components/macro"
import { Form as FormikForm } from "formik"
import { Title as CenteredTitle } from "../CreateBracketPoolForm"
import { fontFamily, fontWeights, pxToRem, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

export const Title = styled(CenteredTitle)`
  text-align: left;
  text-transform: uppercase;
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;

  .poolForm__actionBoxWrapper {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    position: relative;
    margin-top: 0.5rem;

    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 3rem);
      left: -1.5rem;
      display: block;
      background-color: ${bracketTheme.colors.overLight.white90};
      top: 0;
    }

    .poolForm__actionBox {
      grid-column: 4/-4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem 0;

      * {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`
export const FormWrapper = styled.div`
  background: ${bracketTheme.colors.overLight.white};
  margin-top: var(--grid-gap);
  box-sizing: border-box;
  border: 1px solid ${bracketTheme.colors.overLight.white90};
  border-radius: 0.5rem;
  padding: 1.25rem 1.5rem 0;
`
export const Row = styled.div`
  --align-label: initial;
  --grid-template-columns: repeat(11, 1fr);
  --grid-template-rows: 1fr;
  --label-column-start: 1;
  --label-column-end: 4;
  --field-column-start: 4;
  --field-column-end: -4;

  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-template-rows: var(--grid-template-rows);
  column-gap: 1rem;
  flex: 1;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: ${fontFamily.base};

  &.variant--page {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &.row--with-helper {
    --grid-template-rows: 1fr, 1.25rem;
  }

  &.row--full-width {
    --grid-template-columns: repeat(6, 1fr);
    --label-column-start: 1;
    --label-column-end: 3;
    --field-column-start: 3;
    --field-column-end: -1;
  }
  &.is-error-message {
    padding-bottom: 0.5rem;
  }
  &.is-radio-group {
    padding-top: 0;
  }

  &.row--top-aligned {
    --align-label: flex-start;
  }

  &.row--multi-line {
    margin-bottom: 0.5rem;
  }

  .row__label {
    grid-column-start: var(--label-column-start);
    grid-column-end: var(--label-column-end);
    cursor: initial;
    margin: 0 0.5rem;
    font-weight: ${fontWeights.bold};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    letter-spacing: -0.1px;
    color: ${palette.gray20};
    align-self: var(--align-label);
  }

  .row__label--required::after {
    content: "*";
    display: inline-block;
  }

  .row__input {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(16)};
    border-radius: ${pxToRem(4)};
    color: ${palette.gray20};
    font-weight: ${fontWeights.regular};
    display: block;
    outline: none;
    width: 100%;
    transition: all 0.2s ease;
  }

  .row__generic {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
  }

  .row__textWrapper {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
    font-weight: ${fontWeights.regular};
    font-size: ${pxToRem(12)};
    line-height: 1rem;
    letter-spacing: -0.5px;
    color: ${palette.gray50};

    &.is-error-message {
      color: ${palette.darkRed};
    }
  }

  .row__selectWrapper {
    max-width: 4rem;
  }
`
