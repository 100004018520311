import { Field, Form, Formik, FormikProps } from "formik"
import * as React from "react"
import LocalKey from "../../../Base/LocalKey"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import PoolAttributeTable from "../components/PoolAttributeTable"
import PoolSettingsEditBtn from "../components/PoolSettingsEditBtn"
// import StepHeader from "../components/StepHeader";
import { IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepFormCols, StepCustomButton } from "../styles/CommonPoolSetup.styles"
import { ConstitutionContainer, ConstitutionForm, PoolSetupBtnsContainer, SettingsH3, SettingsP } from "../styles/PoolSetupStepForm.styles"

export const financesActionName = "finances"

const updateConstitution = (values, mutation, pool, constitutionToggler) => {
  // console.log(`submit!`)
  // console.log(values)
  const variables = {
    seasonId: pool.season.id,
    poolId: pool.id,
    constitution: values.constitution || "",
  }
  constitutionToggler.update(null)
  return mutation({ variables }).then(
    (res) => {
      // console.log(res)
    },
    (err) => {
      window.alert(`There was an error saving your constitution`)
      window.location.reload()
    },
  )
}

export const PoolSetupStepRecap = (props: IPoolSetupStepProps) => {
  // console.dir(props);
  const { pool, switchStep, isUpdate, canEdit, mutation } = props
  const isReactivatedPool = !!pool && !!pool.parentPoolId
  const canEditConstitution = (canEdit || !isUpdate) && !(isReactivatedPool && !isUpdate)
  const isChallengePool = !!pool && !!pool.season.challengePoolSlug
  const canSeeConstitution = !isChallengePool && !!pool && (canEditConstitution || !!pool.constitution)
  return (
    <LocalKey keyName="recap-constitution">
      {(constitutionToggler) => {
        const isEditingMode = !!constitutionToggler.value
        return (
          <>
            <AnalyticScreen
              feature={`${isUpdate ? "initial " : ""}setup`}
              subfeature={`recap`}
              title={`${isUpdate ? "Initial " : ""}Setup - Recap`}
            />
            {/*isUpdate && (
            <StepHeader title={`Pool Settings`} subtitle={pool && pool.name || ""} />
          )*/}
            {canSeeConstitution && (
              <ConstitutionContainer>
                <SettingsH3>
                  Pool Constitution
                  {canEditConstitution && (
                    <PoolSettingsEditBtn
                      className={`toggle-btn is-toggled--${!!isEditingMode}`}
                      as="button"
                      type="button"
                      poolId={pool.id}
                      isClose={!!isEditingMode}
                      attribute={financesActionName}
                      switchStep={() => constitutionToggler.update(!constitutionToggler.value)}
                    />
                  )}
                </SettingsH3>
                <Formik
                  initialValues={{ constitution: pool.constitution || "" }}
                  onSubmit={(values) => updateConstitution(values, mutation, pool, constitutionToggler)}
                  enableReinitialize={true}
                >
                  {(formikBag: FormikProps<{ constitution: string }>) => {
                    // console.log(`"formikBag"`);
                    // console.dir(formikBag);
                    return (
                      <ConstitutionForm as={Form} disabled={!isEditingMode}>
                        {!isEditingMode && (
                          <SettingsP
                            className={`has-value--${!!formikBag.values.constitution}`}
                            dangerouslySetInnerHTML={{
                              __html:
                                formikBag.values.constitution.replace(/\n/g, "<br />") ||
                                "Type in any custom rules here like prizes, fees and custom tie-breakers.",
                            }}
                          />
                        )}
                        <Field
                          name="constitution"
                          component="textarea"
                          maxLength={2000}
                          placeholder="Type in any custom rules here like prizes, fees and custom tie-breakers."
                          disabled={!isEditingMode}
                        />
                        <FormButton as="button" type="submit" disabled={!isEditingMode}>
                          Save
                        </FormButton>
                      </ConstitutionForm>
                    )
                  }}
                </Formik>
              </ConstitutionContainer>
            )}
            {isUpdate || (false && <SettingsP>{pool.constitution || `Enter prizes, fees or other custom rules.`}</SettingsP>)}
            {(isUpdate && <SettingsH3>Pool Rules</SettingsH3>) || (isReactivatedPool && <SettingsH3>Adjust Rules:</SettingsH3>) || null}
            <PoolAttributeTable pool={pool} canEdit={canEdit} switchStep={switchStep} />
            {!isUpdate && (
              <PoolSetupBtnsContainer>
                <PoolSetupStepFormCols>
                  <StepCustomButton as="button" type="button" onClick={(evt) => switchStep("invite", pool.id, evt)}>
                    {isReactivatedPool ? `Re-invite Members` : `Invite Friends`}
                  </StepCustomButton>
                </PoolSetupStepFormCols>
                <PoolSetupStepFormCols>
                  <StepCustomButton as="button" type="button" onClick={(evt) => switchStep(null, pool.id, evt, pool.url)}>
                    Go to Pool
                  </StepCustomButton>
                </PoolSetupStepFormCols>
              </PoolSetupBtnsContainer>
            )}
          </>
        )
      }}
    </LocalKey>
  )
}

export default PoolSetupStepRecap
