import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import styled from "styled-components/macro"
import { colorFor, primaryFor } from "../../../../common/theme-helpers"
import LocalKey from "../../../Base/LocalKey"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { OpacityBtn } from "../../../components/Button"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
// import useControls from "../../../hooks/useControls";
import { fontWeight, pxToRem } from "../../../utils/style-utils"
import { copyToClipboard, getFbSendLink, getInvitationUrl, getPoolUrl, getTwitterSendLink } from "../../../utils/url-utils"
import { InvitationCenterModal, PoolInviteCenter } from "../../PoolPages/containers/PoolInviteCenter"
import { IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { PoolSetupStepFormCols, PoolSetupStepFormColsDl, PoolSetupStepFormRecapContainer, StepCustomButton } from "../styles/CommonPoolSetup.styles"
import usePoolData from "../../../hooks/usePoolData"
import { fontFamily, palette, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ChevRightSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevRight"
import LinkSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Link"
import FacebookSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Facebook"
import TwitterSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Twitter"
import EmailSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Email"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import MultipleEntriesPoolInviteMailCenterModal from "../../PoolPages/containers/MultipleEntriesPoolInviteMailCenter"
import constants from "../../../../common/constants"
import Analytics from "../../../utils/analytics"
import { scrollToTopAfterTimeout } from "../../../components/ScrollToTopOnMount"
import { isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"

const PoolSetupStepInviteShareRow = styled(FlexRow)`
  width: 100%;
  margin: ${pxToRem(12)} 0;
  cursor: pointer;
  @media (pointer: fine) {
    &.mobile-only {
      display: none;
    }
  }
`
const PoolSetupStepInviteShareIcon = styled(FlexCol)`
  background: ${primaryFor};
  color: ${colorFor};
  border-radius: 100%;
  border: ${pxToRem(2)} solid currentColor;
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  font-size: ${pxToRem(16)};
  flex-basis: auto;
`
const PoolSetupStepInviteShareMedium = styled(FlexColGrow)`
  font-size: ${pxToRem(16)};
  padding: 0 ${pxToRem(16)};
  text-transform: uppercase;
  letter-spacing: ${pxToRem(2.4)};
  width: ${pxToRem(125)};
  flex: none;
`
const PoolSetupStepInviteShareBtn = styled(FlexCol)`
  font-size: ${pxToRem(14)};
  color: ${colorFor};
  background-color: rgba(0, 0, 0, 0);
  border: ${pxToRem(2)} solid currentColor;
  font-weight: ${fontWeight.semiBold};
  letter-spacing: ${pxToRem(3.6)};
  border-radius: ${pxToRem(22)};
  padding: ${pxToRem(12)} ${pxToRem(22)};
  flex-basis: auto;
  text-transform: uppercase;
  transition: all 0.2s ease-out;
  &:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`
const PoolSetupStepInviteLabel = styled.div`
  font-weight: ${fontWeight.semiBold};
  letter-spacing: ${pxToRem(1.6)};
  font-size: ${pxToRem(16)};
  color: ${colorFor};
  margin: ${pxToRem(16)} 0;
  text-align: left;
  width: 100%;
  text-transform: uppercase;
`
const PoolSetupStepInviteInput = styled.input`
  font-size: ${pxToRem(14)} !important;
  letter-spacing: 0 !important;
  margin: 0 !important;
`

const PoolSetupStepInviteLaterLink = styled(OpacityBtn)`
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(16)};
  letter-spacing: ${pxToRem(1.1)};
  padding: ${pxToRem(16)} 0;
  width: 100%;
  color: ${colorFor};
  text-decoration: none;
  justify-content: flex-start;
  text-transform: uppercase;
  position: relative;
  & * {
    padding-left: ${pxToRem(8)};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(12)};
  }
`

const PwReminder = styled.p`
  font-weight: ${fontWeight.semiBold};
  text-align: center;
`

const PoolInviteWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    color: ${palette.gray20};
  }

  & > .content-info {
    margin-top: 1rem;
    width: 100%;
  }

  & > .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    padding: 1rem 0;

    &::before {
      content: "";
      height: 1px;
      background-color: ${palette.gray90};
      width: calc(100% + 3rem);
      left: -1.5rem;
      top: 0;
      position: absolute;
    }
  }
`
const InviteSectionWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 5rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  & > .left {
    display: flex;
    align-items: center;
    width: calc(100% - 24.25rem);
    font-family: ${fontFamily.base};
    font-style: normal;
    text-align: left;
    color: ${palette.gray20};
    flex: 1;

    & > .icon-wrap {
      width: 3rem;
      height: 3rem;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid ${palette.gray90};
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${palette.white};

      > svg {
        width: 1.5rem;
        height: 1.5rem;
        color: ${palette.lightBlue3};
      }
    }

    & > .title {
      font-size: 14px;
      font-weight: ${fontWeight.bold};
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      display: flex;
      width: 6rem;
      margin-left: 1rem;
      margin-right: 2.75rem;
    }

    & > .subtitle {
      font-size: 1rem;
      font-weight: ${fontWeight.regular};
      line-height: 19px;
      letter-spacing: 0em;
    }
  }
  & > .right {
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
      color: ${palette.gray50};
    }
  }

  &:not(:first-child)::before {
    content: "";
    height: 1px;
    background-color: ${palette.gray90};
    width: calc(100% + 1.5rem);
    left: 0;
    top: 0;
    position: absolute;
  }

  @media (hover: hover) {
    :hover:not(:active) {
      margin-left: -1.5rem;
      padding-left: 1.5rem;
      margin-right: -1.5rem;
      padding-right: 1.5rem;
      background-color: ${palette.lightestBlue};
      &::before,
      &::after {
        content: "";
        height: 1px;
        background-color: ${palette.gray90};
        width: 100%;
        left: 0;
        position: absolute;
      }
      &::after {
        bottom: 0;
      }
      &::before {
        top: 0;
      }
      &:first-child::before {
        display: none;
      }
      &:last-child::after {
        display: none;
      }

      & + {
        &::before {
          display: none;
        }
      }
    }
  }
`

const noop = () => ({})
const PoolSetupStepInviteShare = ({ icon, medium, href = "", onClick = noop }) => {
  const hrefContent = (
    <PoolSetupStepInviteShareBtn as="a" rel="noopener nofollow" target="_blank" href={href} onClick={onClick}>
      Share
    </PoolSetupStepInviteShareBtn>
  )
  const nonHrefContent = (
    <PoolSetupStepInviteShareBtn as="button" type="button" onClick={onClick}>
      Share
    </PoolSetupStepInviteShareBtn>
  )
  if (href) {
    return (
      <PoolSetupStepInviteShareRow as="a" rel="noopener nofollow" target="_blank" href={href} className={medium === "Text" ? "mobile-only" : ""}>
        <PoolSetupStepInviteShareIcon className={`icon-moon-${icon}`} />
        <PoolSetupStepInviteShareMedium>{medium}</PoolSetupStepInviteShareMedium>
        {hrefContent}
      </PoolSetupStepInviteShareRow>
    )
  }
  return (
    <PoolSetupStepInviteShareRow className={medium === "Text" ? "mobile-only" : ""} onClick={onClick}>
      <PoolSetupStepInviteShareIcon className={`icon-moon-${icon}`} />
      <PoolSetupStepInviteShareMedium>{medium}</PoolSetupStepInviteShareMedium>
      {nonHrefContent}
    </PoolSetupStepInviteShareRow>
  )
}

const PoolSetupStepInvite: React.FC<IPoolSetupStepProps> = (props) => {
  const { switchStep, pool } = props
  const isReactivatedPool = !!pool && !!pool.parentPoolId
  const match = useRouteMatch<any>()
  const location = useLocation()
  const history = useHistory()
  const poolData = usePoolData({ location, history, match })
  // const match = useRouteMatch()

  useEffect(() => {
    return () => {
      scrollToTopAfterTimeout()
    }
  }, [])

  if (isReactivatedPool) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const other = { location, history, match, isModal: false, isNexusStyles: false, poolData }
    if (location && match && history) {
      return (
        <>
          <PoolInviteCenter {...other} />
          <PoolSetupStepFormCols>
            <StepCustomButton as="button" type="button" onClick={(evt) => switchStep(null, pool.id, evt)}>
              Go to Your Pool
            </StepCustomButton>
          </PoolSetupStepFormCols>
        </>
      )
    }
  }
  const poolPassword = poolData.poolDetail?.password || undefined
  return <PoolSetupStepInviteInner {...props} poolData={poolData} poolPassword={poolPassword} />
}

const InviteSection = ({
  name,
  title,
  subtitle,
  icon,
  onClick,
  disabled,
  dataCyTitle,
  dataCySubtitle,
  ...rest
}: {
  name: string
  title: string
  subtitle: string
  icon: React.ReactElement
  disabled: boolean
  onClick?: (event: React.SyntheticEvent) => void
  dataCyTitle?: string
  dataCySubtitle?: string
} & any) => {
  return (
    <InviteSectionWrap
      {...rest}
      className={cx({
        [`setion--${name}`]: true,
        [`disabled`]: disabled,
      })}
      onClick={onClick}
    >
      <div className="left">
        <div className="icon-wrap">{icon}</div>
        <span className="title" data-cy={dataCyTitle}>
          {title}
        </span>
        <span className="subtitle" data-cy={dataCySubtitle}>
          {subtitle}
        </span>
      </div>
      <div className="right">
        <ChevRightSvg />
      </div>
    </InviteSectionWrap>
  )
}

const PoolSetupStepInviteInner = (props: IPoolSetupStepProps & { poolPassword?: string; subtitle?: string } & any) => {
  const { entryId, pool, poolPassword, areaHasMultipleEntriesPerUser, switchStep, isUpdate, subtitle, gameInstanceUid } = props
  const [copyToClipboardToastId, setCopyToClipboardToastId] = useState<string | number>("")
  const [showEmailModal, setShowEmailModal] = useState(false)

  const poolUrl = getPoolUrl(pool)

  const handleCopyToClipboard = (evt, useToast = false) => {
    Analytics.trackAction("brackets welcome", "sharing", "copy")
    evt.preventDefault()
    if (typeof document !== "undefined") {
      const value = getInvitationUrl({ medium: "copy", pool, entryId })
      copyToClipboard(value)
      if (useToast) {
        if (!copyToClipboardToastId) {
          const toastId = toast.snackbar("Pool invite link copied to clipboard", {
            position: "bottom-center",
            onClose: () => setCopyToClipboardToastId(""),
          })
          setCopyToClipboardToastId(toastId)
        }
      } else {
        const targetEl = evt.target
        targetEl.setAttribute("disabled", true)
        const origHtml = targetEl.innerHTML
        targetEl.innerHTML = "Copied!"
        setTimeout(() => {
          if (targetEl) {
            targetEl.removeAttribute("disabled")
            targetEl.innerHTML = origHtml
          }
        }, 1500)
      }
    }
  }

  const openModal = React.useCallback(() => {
    Analytics.trackAction("brackets welcome", "sharing", "email")
    setShowEmailModal(true)
  }, [])
  const closeModal = React.useCallback(() => {
    setShowEmailModal(false)
  }, [])

  if (areaHasMultipleEntriesPerUser) {
    const poolData = {
      ...props.poolData,
      entry: props.entry,
      entryId: props.entryId,
      entryName: props.entryName,
    }
    const { poolDetail, currentUser } = props.poolData || emptyObject
    const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)
    const gender = isWomenBracket ? "Women's" : "Men's"
    const emailDefaultMessage = `${gender} Brackets are back and I've started a pool${
      poolDetail?.name ? `, ${poolDetail.name},` : ""
    } on CBSSports.com. Join now before the tournament starts so we can compete all of March Madness.`
    const emailDefaultSubject = `${currentUser?.preferredEntryName || "Manager"} has invited you to a ${gender} bracket pool!`
    const onFacebookOrTwitterClick = (location: string) => {
      Analytics.trackAction("brackets welcome", "sharing", location)
    }
    return (
      <>
        <PoolInviteWrap>
          <span className="title" data-cy="invite-header">
            {subtitle}
          </span>
          <div className="content-info">
            <InviteSection
              name="link"
              title="Copy Link"
              subtitle="Copy and paste your pool’s invite URL"
              icon={<LinkSvg />}
              onClick={(e) => handleCopyToClipboard(e, true)}
              dataCyTitle="invite-copy-link-title"
              dataCySubtitle="invite-copy-link-subtitle"
            />
            <InviteSection
              name="link"
              title="Email"
              subtitle="Email an invite to your pool"
              icon={<EmailSvg />}
              onClick={openModal}
              disabled={!constants.PUBLIC_SMTP_ENABLED}
              dataCyTitle="invite-email-title"
              dataCySubtitle="invite-email-subtitle"
            />
            <InviteSection
              name="link"
              title="Facebook"
              subtitle="Send pool invites via Facebook"
              icon={<FacebookSvg />}
              as="a"
              rel="noopener nofollow"
              target="_blank"
              href={getFbSendLink(getInvitationUrl({ medium: "facebook", pool, entryId }), poolUrl)}
              onClick={() => onFacebookOrTwitterClick("facebook")}
              dataCyTitle="invite-facebook-title"
              dataCySubtitle="invite-facebook-subtitle"
              data-cy="invite-facebook-link"
            />
            <InviteSection
              name="link"
              title="X"
              subtitle="Send pool invites via X"
              icon={<TwitterSvg />}
              as="a"
              rel="noopener nofollow"
              target="_blank"
              href={getTwitterSendLink(getInvitationUrl({ medium: "twitter", pool, entryId }), poolUrl)}
              onClick={() => onFacebookOrTwitterClick("twitter")}
              dataCyTitle="invite-twitter-title"
              dataCySubtitle="invite-twitter-subtitle"
              data-cy="invite-twitter-link"
            />
          </div>
          <div className="actions">
            <Button variant="primary" onClick={(evt) => switchStep(null, pool.id, evt, `${pool.url}/standings`, true)} data-cy="pool-home-btn">
              Pool Home
            </Button>
          </div>
          <MultipleEntriesPoolInviteMailCenterModal
            {...props}
            poolData={poolData}
            onClose={closeModal}
            isOpen={showEmailModal}
            defaultMessage={emailDefaultMessage}
            defaultSubject={emailDefaultSubject}
          />
        </PoolInviteWrap>
      </>
    )
  }

  return (
    <LocalKey keyName="modal">
      {(modals) => {
        return (
          <React.Fragment>
            <AnalyticScreen
              feature={`${isUpdate ? "initial " : ""}setup`}
              subfeature={"invite friends"}
              title={`${isUpdate ? "Initial " : ""}Setup - Invite Friends`}
            />
            <PwReminder>Please remember to include your password{poolPassword ? `: '${poolPassword}'` : poolPassword}</PwReminder>
            <PoolSetupStepFormRecapContainer className="invite-step">
              <PoolSetupStepFormColsDl>
                <PoolSetupStepInviteShare icon="envelope" medium="Email" onClick={() => modals.update(InvitationCenterModal.modalKey)} />
                {/*<PoolSetupStepInviteShare
                  className="mobile-only"
                  icon="comment"
                  medium="Text"
                  href={`sms:?body=${encodeURIComponent(`You are invited to my ${invitationText}: ${this.getPoolInvitationUrl("text")}`)}`}
                />*/}
                <PoolSetupStepInviteShare
                  icon="facebook"
                  medium="Facebook"
                  href={getFbSendLink(getInvitationUrl({ medium: "facebook", pool, entryId }), poolUrl)}
                />
                {/*<PoolSetupStepInviteShare
                  icon="envelope"
                  medium="Email"
                  href={`mailto:?subject=${encodeURIComponent(`Join my CBS pool: ${pool.name}`)}&body=${encodeURIComponent(`Join my CBS pool: ${this.getPoolInvitationUrl("email")}`)}`}
                />
                <PoolSetupStepInviteShare
                  icon="cbs-eye"
                  medium="CBS Contacts"
                  onClick={() => modals.update(InvitationCenterModal.modalKey)}
                />*/}
              </PoolSetupStepFormColsDl>
              <PoolSetupStepFormColsDl>
                <PoolSetupStepInviteLabel>Your Pool URL</PoolSetupStepInviteLabel>
                <PoolSetupStepInviteInput readOnly={true} type="text" value={getInvitationUrl({ medium: "copy", pool, entryId })} />
                <PoolSetupStepInviteLaterLink as="button" type="button" onClick={handleCopyToClipboard}>
                  Copy To Clipboard
                  <div className="icon-moon-file-text-o" />
                </PoolSetupStepInviteLaterLink>
              </PoolSetupStepFormColsDl>
            </PoolSetupStepFormRecapContainer>
            <PoolSetupStepFormCols>
              <StepCustomButton as="button" type="button" onClick={(evt) => switchStep(null, pool.id, evt, pool.url)}>
                Go to Your Pool
              </StepCustomButton>
            </PoolSetupStepFormCols>
            <InvitationCenterModal
              poolData={props.poolData}
              isOpen={modals.value === InvitationCenterModal.modalKey}
              close={() => modals.update(null)}
            />
          </React.Fragment>
        )
      }}
    </LocalKey>
  )
}

export default PoolSetupStepInvite
