import * as React from "react"
import { GameTypeEnum } from "../../../../common/enums"
import { getSegment } from "../../../../common/pool-settings-setup"
import { snakeCase } from "../../../../common/string-utils"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { stepMappingFor, weightsAndRoundsField } from "../components/StepMappings"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import {
  PoolSetupStepFormColDesktopDescription,
  PoolSetupStepFormColPEmpty,
  PoolSetupStepFormCols,
  PoolSetupStepFormRow,
  StepCustomButton,
} from "../styles/CommonPoolSetup.styles"

// const setValue = (props: IPoolSetupStepProps, name?: string, value?: any) => async (event) => setValueSubmit(props, name, value, event);
const setValue = (props: IPoolSetupStepProps & IPoolSetupStepFormValues, name?: string, value?: any) => async (_event) => {
  // console.log(`setValue name: '${name}' => '${value}'`)
  // console.dir(props.values)
  // console.dir(props)
  if (name === weightsAndRoundsField) {
    const isBracket = getSegment(props.pool).gameType === GameTypeEnum.BRACKET
    const gameWeightType = isBracket ? "MULTIPLY_SEED" : "CONFIDENCE"
    if (value === "NONE") {
      props.setFieldValue("gameWeightType", "NONE", false)
      props.setFieldValue("roundBonusType", "NONE", false)
      // props.setFieldValue("roundBonuses", undefined, false);
    }
    if (value === "GAME_WEIGHTS_ONLY") {
      props.setFieldValue("gameWeightType", gameWeightType, false)
      props.setFieldValue("roundBonusType", "NONE", false)
      // props.setFieldValue("roundBonuses", undefined, false);
    }
    if (value === "ROUND_BONUSES_ONLY") {
      props.setFieldValue("gameWeightType", "NONE", false)
      props.setFieldValue("roundBonusType", "STANDARD", false)
    }
    if (value === "BOTH") {
      props.setFieldValue("gameWeightType", gameWeightType, false)
      props.setFieldValue("roundBonusType", "STANDARD", false)
    }
  } else if (name === "includeChampionshipRound") {
    props.setFieldValue(name, value === "true", false)
  } else {
    props.setFieldValue(name, value, false)
  }
  // console.log(`setValue ${props.values.mainTiebreaker}`)
  // console.dir(props.values)
  // Hack to wait for new value to be applied
  // Pending https://github.com/jaredpalmer/formik/issues/529
  // await Promise.resolve()
  // console.dir(props.values)
  // console.log(`setValue after ${props.values.mainTiebreaker}`)
  // https://github.com/jaredpalmer/formik/issues/529#issuecomment-402763988
  // setTimeout(props.submitForm, 1000)
  props.submitForm()
}

const PoolSetupGenericChoices = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  const { currentStep, isUpdate, pool, seasonType, gameType, values } = props
  // console.log('PoolSetupGenericChoices')
  // console.dir(props)
  const stepMappings = stepMappingFor(pool.gameInstanceUid, seasonType, gameType, pool)
  const stepMapping = stepMappings.find((m) => m.path === currentStep)
  if (!stepMapping || !stepMapping.choices || stepMapping.choices.length === 0) {
    // console.warn(`stepMapping: ${currentStep}`);
    return null
  } else {
    // console.log(`PoolSetupGenericChoices: ${props.values.mainTiebreaker}`)
    // console.dir(props)
    // console.dir(stepMapping)
    const analyticName = snakeCase(currentStep).replace(/\_/g, " ")
    return (
      <PoolSetupStepFormRow>
        <AnalyticScreen
          feature={`${isUpdate ? "initial " : ""}setup`}
          subfeature={analyticName}
          title={`${isUpdate ? "Initial " : ""}Setup - ${analyticName}`}
        />
        {stepMapping.choices
          .filter((choice) => !(choice.unlessFunc && choice.unlessFunc(pool, values)))
          .map((choice, i) => {
            const describedById = (choice.description && `${stepMapping.field}-${choice.value}-db`) || undefined
            const isDesktopAlt = false // choice.isDesktopAlt
            const description = (choice.bodyFunc && choice.bodyFunc(pool, values)) || choice.description
            const cta = (choice.humanFunc && choice.humanFunc(pool, values)) || choice.human
            return (
              <PoolSetupStepFormCols key={i} className={isDesktopAlt ? "is-desktop-alt" : ""}>
                <StepCustomButton
                  as={"button" as any}
                  type="button"
                  onClick={setValue(props, stepMapping.field, choice.value)}
                  ariaDescribedby={describedById}
                  dangerouslySetInnerHTML={{ __html: cta }}
                />
                {description ? (
                  <PoolSetupStepFormColDesktopDescription id={describedById}>{description}</PoolSetupStepFormColDesktopDescription>
                ) : description === null ? null : (
                  <PoolSetupStepFormColPEmpty />
                )}
              </PoolSetupStepFormCols>
            )
          })}
      </PoolSetupStepFormRow>
    )
  }
}

export default PoolSetupGenericChoices
