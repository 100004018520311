import React from "react"
// import { withTheme } from "styled-components/macro";
import { PoolFormStepperBar, PoolFormStepperContainer } from "../styles/PoolFormStepper.styles"

const PoolFormStepper = ({ percent }) => (
  <PoolFormStepperContainer>
    <PoolFormStepperBar percent={percent} />
  </PoolFormStepperContainer>
)

export default PoolFormStepper
