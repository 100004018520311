import React from "react"
import { getSegment } from "../../../../common/pool-settings-setup"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import { IPoolSetupStepFormValues, IPoolSetupStepProps } from "../PoolSetupTypes.d"
import { FormButton, PoolSetupStepFormColDesktopDescription, PoolSetupStepFormCols, PoolSetupStepFormRow } from "../styles/CommonPoolSetup.styles"
import { TournamentBtn, TournamentBtns } from "../styles/PoolSetupStepTournamentSelect.styles"
import { confTourneyDesc } from "./StepMappings"

const PoolSetupStepTournamentSelect = (props: IPoolSetupStepProps & IPoolSetupStepFormValues) => {
  // console.log("PoolSetupStepTournamentSelect");
  // console.dir(props);
  const { pool, isUpdate, setFieldValue } = props
  const segment = getSegment(pool)
  const tournaments = segment.tournaments || ([] as any[])
  const values = (props.values.tournamentIds || []) as number[]
  return (
    <React.Fragment>
      <AnalyticScreen
        feature={`${isUpdate ? "initial " : ""}setup`}
        subfeature={"set tournaments"}
        title={`${isUpdate ? "Initial " : ""}Setup - Set Tournaments`}
      />
      <PoolSetupStepFormRow>
        <TournamentBtns>
          {tournaments.map((tournament) => {
            const idx = values.indexOf(tournament.cbsId)
            const isSelected = idx >= 0
            const onClick = () => {
              const newValues = ([] as number[]).concat(values)
              if (isSelected) {
                newValues.splice(idx, 1)
              } else {
                newValues.push(tournament.cbsId)
              }
              // prevent no entries
              if (newValues.length > 0) {
                setFieldValue("tournamentIds", newValues.sort())
              }
            }
            return (
              <TournamentBtn
                key={tournament.id}
                type="button"
                disabled={values.length < 2 && isSelected}
                className={`is-selected--${isSelected}`}
                onClick={onClick}
              >
                <CheckmarkSvg />
                {tournament.abbrev}
              </TournamentBtn>
            )
          })}
        </TournamentBtns>
      </PoolSetupStepFormRow>
      <PoolSetupStepFormRow>
        <PoolSetupStepFormCols>
          <FormButton as="button" type="submit">
            Continue
          </FormButton>
          <PoolSetupStepFormColDesktopDescription>{confTourneyDesc}</PoolSetupStepFormColDesktopDescription>
        </PoolSetupStepFormCols>
      </PoolSetupStepFormRow>
    </React.Fragment>
  )
}

export default PoolSetupStepTournamentSelect
