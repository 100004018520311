import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  bgColor?: string
  icon?: boolean
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(({ color = "currentColor", icon = false, bgColor, className, ...props }, ref) => {
  const classNames = [`checkmark`]
  if (className) {
    classNames.push(className)
  }
  if (icon) {
    classNames.push("is-icon--true")
  }
  return (
    <SVG viewBox="0 0 44 44" className={classNames.join(" ")} {...props} ref={ref}>
      <g fill="none" fillRule="evenodd">
        {(bgColor && <circle fill={bgColor} className="checkmark__circle" cx="21.985" cy="21.7" r="21.395" />) || null}
        <path
          fill={color}
          className="checkmark__check"
          d="M15.49 21l4.38 4.2s7.34-8.6 11.19-12.9c-.17 2.5.88 2 2.05 4.6-3.86 2.4-10.9 10.6-12.9 14.2-2.84-3.4-5.32-6-7.66-6.9l2.94-3.2z"
        />
      </g>
    </SVG>
  )
})

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
