import styled from "styled-components/macro"
import { themeBtnBgColor, themeBtnHighlightBgColor } from "../../../../common/theme-helpers"
import { layout, pxToRem } from "../../../utils/style-utils"
import { Stepper } from "../PoolSetupTypes.d"
const stepContainerH = 14
const stepInnerH = stepContainerH - 8

export const PoolFormStepperContainer = styled.div`
  position: relative;
  background-color: rgba(3, 5, 27, 0.85);
  border-radius: ${pxToRem(stepContainerH)};
  box-shadow: inset 0 ${pxToRem(14)} ${pxToRem(12)} 0 rgba(0, 0, 0, 0.44);
  width: 90%;
  height: ${pxToRem(stepContainerH)};
  min-height: ${pxToRem(stepContainerH)};
  @media (min-width: ${pxToRem(layout.formHiddenLabelsW)}) {
    max-width: ${pxToRem(300)};
    border: ${pxToRem(1)} solid rgba(0, 114, 188, 0.85);
  }
`
export const PoolFormStepperBar = styled.div<Stepper>`
  background-image: linear-gradient(-90deg, ${themeBtnHighlightBgColor} 0%, ${themeBtnBgColor} 100%);
  border-radius: ${pxToRem(stepInnerH)};
  transition: width 1.2s ease-out;
  position: absolute;
  width: ${({ percent }) => `${percent}%`};
  top: ${pxToRem((stepContainerH - stepInnerH) / 2)};
  left: ${pxToRem((stepContainerH - stepInnerH) / 2)};
  height: ${pxToRem(stepInnerH)};
`
